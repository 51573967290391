import React from "react";
import { useDispatch, useSelector } from "react-redux";
/* import Dropdown from "./Dropdown"; */
import { Link, NavLink } from "react-router-dom";
/*import { useHistory } from "react-router-dom";*/
import { logout, logoutUser } from "../../store/auth";

export default function Navbar() {
  const navbarColor = useSelector((state) => state.ui.navbarColor);
  const user = useSelector((state) => state.auth.currentUser);
  const dispatch = useDispatch();
  //const history = useHistory();
  const state = {
    isOpen: true,
  };

  const onChange = (isOpen) => {
    this.setState({
      isOpen,
    });
  };

  const handleLogout = async (e) => {
    e.preventDefault(); 
    dispatch(logoutUser());
    window.location.reload();
  }

  return (
    <header>
      <nav
        className={`navbar fixed-top navbar-expand-lg navbar-dark ${
          navbarColor === "transparent" ? "bg--transparent" : "bg--colored"
        }`}
      >
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <Link className="navbar-brand d-flex align-items-center" to="/">
            <img className="me-1" src="/logo192.png" alt="" height="30px" />
            AlFlay
          </Link>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink className="nav-link" to="">
                  Principal
                </NavLink>
              </li>
              {user.name === null && (
                <li className="nav-item">
                  <NavLink className="nav-link" to="/access?target=login">
                    Inicia sesión
                  </NavLink>
                </li>
              )}
              {user.name === null && (
                <li className="nav-item">
                  <NavLink className="nav-link" to="/access?target=register">
                    Crea tu cuenta
                  </NavLink>
                </li>
              )}
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/management/dealer"
                >
                  Reparte con nosotros
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/management/businesses"
                >
                  Inscribe tu negocio
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/dashboard">
                  Dashboard
                </NavLink>
              </li>
              {user.name !== null && (
                <li className="nav-item dropdown">
                  <NavLink
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                  <b className="text-capitalize">{user.name}</b>
                  </NavLink>
                  <ul
                    className="dropdown-menu dropdown-menu-dark dropdown-menu-end"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <li>
                      <NavLink className="dropdown-item" to="/profile">
                        Profile
                      </NavLink>
                    </li>
                    {/* <li>
                      <NavLink className="dropdown-item" to="/management">
                        Management
                      </NavLink>
                    </li> */}
                    <li>
                      <NavLink className="dropdown-item" to="#" onClick={async (e) => { handleLogout(e); }}>
                      <i className="bi bi-box-arrow-right float-end"></i>
                        Salir
                      </NavLink>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

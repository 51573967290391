import React from 'react';
import PropTypes from 'prop-types';

const WidgetIcon = ({ icon, gradient }) => {
  return (
    <div className={`icon__container ${gradient}`}>
      <i className={`bi-${icon} icon`}></i>
    </div>
  );
};

WidgetIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  gradient: PropTypes.oneOf([
    'gradient--green',
    'gradient--lime',
    'gradient--blue',
    'gradient--purple',
    'gradient--bruise',
    'gradient--red',
    'gradient--premium-dark',
    'gradient--morpheus-den',
    'gradient--malibu-beach',
    'gradient--new-life',
    'gradient--plum-palette',
    'gradient--everlasting-sky',
    'gradient--happy-fisher',
    'gradient--itmeo-branding',
    'gradient--zeus-miracle',
    'gradient--deep-blue',
    'gradient--mixed-hopes',
    'gradient--fly-high',
    'gradient--snow-again',
    'gradient--february-ink',
    'gradient--grown-early',
    'gradient--sharp-blue',
    'gradient--love-kiss',
    'gradient--phoenix-start',
    'gradient--october-silence',
    'gradient--mass-party',
    'gradient--hidden-jaguar',
    'gradient--arielle-s-smile',
    'gradient--star-wine',
    'gradient--strong-bliss',
  ]).isRequired,
};

export default WidgetIcon;

import React from 'react';
import PropTypes from 'prop-types';

const SearchBar = ({
  criteria,
  location,
  category,
  categories,
  error,
  dataList,
  selectPlaceholder,
  onCriteriaChange: handleCriteriaChange,
  onLocationChange: handleLocationChange,
  onCategoryChange: handleCategoryChange,
  onSubmit: handleSubmit,
}) => {
  return (
    <div
      className={`search-bar search-bar--cover mt-5 p-3 p-lg-1 ps-lg-4 ${
        error ? 'search-bar--error' : ''
      }`}>
      <form onSubmit={handleSubmit}>
        <div className='row'>
          <div className='col-lg-3 d-flex align-items-center form-group'>
            <input
              className={`form-control border-0 shadow-0 ${
                error?.criteria ? 'input-error' : ''
              }`}
              type='search'
              name='search'
              id='search'
              value={criteria}
              onChange={handleCriteriaChange}
              autoFocus
              placeholder={
                error?.criteria ? error.criteria : '¿Qué estás buscando?'
              }
            />
          </div>
          <div className='col-lg-4 d-flex align-items-center form-group '>
            <div className='input-label-absolute input-label-absolute-right w-100'>
              <input
                className={`form-control border-0 shadow-0 ${
                  error?.location ? 'input-error' : ''
                }`}
                type='text'
                name='location'
                id='location'
                autoComplete='off'
                list='place-predictions'
                value={location}
                onChange={handleLocationChange}
                placeholder={error?.location ? error.location : '¿Dónde?'}
              />
              <datalist id='place-predictions'>
                {dataList?.map((option, index) => (
                  <option key={index}>{option.display_name}</option>
                ))}
              </datalist>
            </div>
          </div>
          <div className='col-lg-3 d-flex align-items-center form-group no-divider'>
            <select
              className={`form-select bootstrap-select border-0 shadow-0 ${
                category === selectPlaceholder.id ? 'select-placeholder' : ''
              } ${error?.category ? 'input-error' : ''}`}
              value={category}
              onChange={handleCategoryChange}
              aria-label='Default select example'>
              {categories?.map((category) =>
                category.id !== selectPlaceholder.id ? (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ) : (
                  <option key={category.id} hidden>
                    {category.name}
                  </option>
                )
              )}
            </select>
          </div>
          <div className='col-lg-2 d-grid'>
            <button
              className='btn btn-primary rounded-pill h-100'
              type='submit'
              id='button-addon1'>
              Buscar
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

SearchBar.propTypes = {
  criteria: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  category: PropTypes.number.isRequired,
  error: PropTypes.object,
  dataList: PropTypes.array,
  categories: PropTypes.array.isRequired,
  onCriteriaChange: PropTypes.func.isRequired,
  onLocationChange: PropTypes.func.isRequired,
  onCategoryChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectPlaceholder: PropTypes.object.isRequired,
};

SearchBar.defaultProps = {
  selectPlaceholder: { id: -1024, name: '¿De qué categoría?' },
};

export default SearchBar;

import React from 'react';
import { AttributionControl, MapContainer, TileLayer } from 'react-leaflet';
import PropTypes from 'prop-types';
import GestureHandling from 'leaflet-gesture-handling';
import L from 'leaflet';

const Map = ({ children }) => {
  L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

  return (
    <MapContainer
      center={{ lat: 21.5, lng: -79.5 }}
      zoom={7}
      scrollWheelZoom={true}
      doubleClickZoom={true}
      attributionControl={false}
      gestureHandling={true}>
      <TileLayer
        attribution={`&copy; ${new Date().getFullYear()} <a href="http://alflay.com">AlFlay</a> Todos los derechos reservados`}
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      <AttributionControl position='bottomright' prefix={false} />
      {children}
    </MapContainer>
  );
};

Map.propTypes = {
  children: PropTypes.array.isRequired,
};

export default Map;

import React from 'react';
import { Link } from 'react-router-dom';

const Partner = () => {
  const cards = [
    {
      title: 'Reparte con nosotros',
      text: 'Descubre una forma divertida de ganar dinero y trabaja las horas y días que tú quieras. Con nosotros, te convertirás en tu propio jefe.',
      imagePath: 'courier.jpg',
      calloutText: '¡Empieza ya!',
      calloutURL: '#',
    },
    {
      title: 'Inscribe tu negocio',
      text: 'Saca provecho de nuestras herramientas, nuestra tecnología y nuestra base de clientes. Genera más ventas y date a conocer ante cientos de usuarios.',
      imagePath: 'business.jpg',
      calloutText: '¡Únete a nosotros!',
      calloutURL: '#',
    },
    {
      title: 'Añade tu ciudad',
      text: 'Conviértete en el representante de AlFlay en tu localidad. Ayúdanos a expandir nuestros servicios y encuentra todo un mundo nuevo de oportunidades.',
      imagePath: 'city.jpg',
      calloutText: '¡Contáctanos ahora!',
      calloutURL: '#',
    },
  ];

  return (
    <section className='pt-5 mt-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 m-auto text-center'>
            <h2 className='display-3'>¡Únete a nosotros!</h2>
            <p className='mb-5'>
              ¿Quieres formar parte de AlFlay? Aquí te mostramos cómo hacerlo
            </p>
          </div>
        </div>
        <div className='row justify-content-center partner'>
          {cards.map((card) => (
            <article key={card.title} className='col-lg-4 mb-5 partner__card'>
              <div className='card border-0 mb-3 h-100'>
                <img
                  className='card-img-top'
                  src={require(`../../assets/img/${card.imagePath}`).default}
                  alt=''
                />
                <div className='card-body'>
                  <h5 className='card-title'>{card.title}</h5>
                  <p className='card-text'>{card.text}</p>
                </div>
                <div className='card-footer bg-white border-0'>
                  <p className='card-text'>
                    <Link
                      className='btn btn-primary w-100'
                      to={card.calloutURL}>
                      {card.calloutText}
                    </Link>
                  </p>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Partner;

import { toast } from 'react-toastify';
import { apiCallFailed } from '../api';
import { loginUserFailed } from '../auth';

export const toastNotifications = (store) => (next) => (action) => {
  action.type === apiCallFailed.type &&
    toast.error('Lo sentimos, ha ocurrido un error');
  next(action);
  action.type === loginUserFailed.type &&
    toast.error(action.payload);
  next(action);
};

import React from 'react';
import PropTypes from 'prop-types';

const Cover = (props) => {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center landing__cover'>
      <div className='landing__cover-overlay'></div>
      {props.children}
    </div>
  );
};

Cover.propTypes = {
  children: PropTypes.object.isRequired,
};

export default Cover;

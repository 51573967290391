import React from 'react';
import PropTypes from 'prop-types';

const TabPane = ({ isActive, children }) => {
  return (
    <div className={`tab__pane ${isActive ? 'active' : ''}`.trim()}>
      {children}
    </div>
  );
};

TabPane.propTypes = {
  isActive: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
};

export default TabPane;
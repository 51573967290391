import React from 'react';
import PropTypes from 'prop-types';

const Widget = ({ children, color, direction }) => {
  return (
    <div className={`widget widget--${direction} widget--${color}`}>
      {children}
    </div>
  );
};

Widget.propTypes = {
  children: PropTypes.array.isRequired,
  color: PropTypes.oneOf(['light', 'dark']).isRequired,
  direction: PropTypes.oneOf(['row', 'column']),
};

Widget.defaultProps = {
  color: 'light',
  direction: 'row',
};

export default Widget;

/* eslint-disable array-callback-return */
import axios from "axios";
import { useState, useEffect, useCallback } from "react";

const useForm = (callback, validate) => {
  const [values, setValues] = useState({
    username: "",
    telephone: "",
    email: "",
    password: "",
    password2: "",
  });
  const [errors, setErrors] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
 /*  const http = axios.create({
    baseURL: "http://alflay.com/api",
    headers: {
      "Content-type": "application/json",
      'Access-Control-Allow-Origin': '*',
    }
  }); */

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!e.target.reportValidity()){
      return e.target.reportValidity();
    }
    setErrors(validate(values));
    setIsSubmitting(true);
   /*  http.post("/User/1", {
        Name: values.name,
        UserLastName: values.username,
        Picture: "srcImages\\imgBussines\\Category_Snack.jpg",
        Active: false,
        UserName: values.username,
        Email: values.email,
        PasswordHash: values.password,
        PhoneNumber: values.telephone,
      }).then(res => console.log(res))
      .catch(err => console.log(err)); */
/*
      await axios.post(
        'http://alflay.com/api/User/1',
        {
          Name: values.name,
          UserLastName: values.username,
          Picture: "srcImages\\imgBussines\\Category_Snack.jpg",
          Active: false,
          UserName: values.username,
          Email: values.email,
          PasswordHash: values.password,
          PhoneNumber: values.telephone
        },{
          "Content-type": "application/json",
          'Access-Control-Allow-Origin': '*',
          mode: 'no-cors'
      }
        ).then(response => {
            console.log("Success ========>", response);
        })
        .catch(error => {
            console.log("Error ========>", error);
        }
    )
*/
    
      // await fetch('http://alflay.com/api/User/1', {
      //       method: 'POST',
      //       headers: {Accept: '*/*', "Content-Type": "application/json", Authorization: 'Basic'},
      //       body: JSON.stringify({
      //         Name: values.name,
      //         UserLastName: values.username,
      //         Picture: "srcImages\\imgBussines\\Category_Snack.jpg",
      //         Active: false,
      //         UserName: values.username,
      //         Email: values.email,
      //         PasswordHash: values.password,
      //         PhoneNumber: values.telephone
      //       })
      //   }).then(response => {
      //     console.log(response.json())
      //   })
      //   .then(json => {
      //     console.log(json)
      //   });

  };

  const onCallback = useCallback(async (values) => {
    return await axios.post(
      'http://alflay.com/api/User/1',
      {
        Name: values.name,
        UserLastName: values.username,
        Picture: "srcImages\\imgBussines\\Category_Snack.jpg",
        Active: false,
        UserName: values.username,
        Email: values.email,
        PasswordHash: values.password,
        PhoneNumber: values.telephone
      },{
        "Content-type": "application/json",
        'Access-Control-Allow-Origin': '*',
        mode: 'no-cors'
    })
  }, [isSubmitting])

  useEffect(() => {
    //console.log('useEffect ', Object.keys(errors).length, errors)
    if (Object.keys(errors).length === 0 && isSubmitting) {
      onCallback(values).then(response => {
        //console.log("Success ========>", response);
        callback();
      })
      .catch( (error) => {
          if(error.response && error.response.data.length)
          error.response.data.map((el) => {
            const { code , description} = el;
            //console.log("Error ========> code:",code, " description:", description);

            if(/UserName/.test(code)){
              setErrors(prev => ({ ...prev, username: description}));
            }
            if(/Email/.test(code)){
              setErrors(prev => ({ ...prev, email: description}));
            }
            if(/PasswordHash/.test(code)){
              setErrors(prev => ({ ...prev, password: description}));
            }
          });
      });
    }
    setIsSubmitting(false);
  }, [onCallback]);

  return { handleChange, handleSubmit, values, errors, isSubmitting };
};

export default useForm;
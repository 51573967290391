import React, { useEffect, useState } from 'react';
import Map from '../common/Map';
import { useSelector } from 'react-redux';
import LocationMarker from '../common/LocationMarker';
import BusinessMarker from '../common/BusinessMarker';
import ServiceMarker from '../common/ServiceMarker';

const Presence = () => {
  const businesses = useSelector((state) => state.entities.businesses);
  const categories = useSelector((state) => state.entities.categories);
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    setMarkers(
      businesses.list.map((business) => ({
        ...businesses.byId[business],
        type: categories.byId[businesses.byId[business].categoryId]?.name,
      }))
    );
  }, [businesses, categories]);

  return (
    <section className='pt-5 mt-5'>
      <div className='row w-100'>
        <div className='col-lg-8 m-auto text-center'>
          <h2 className='display-3'>¿Dónde estamos?</h2>
          <p className='mb-5'>
            Descubre dónde puedes disfrutar nuestras propuestas
          </p>
        </div>
      </div>
      <div className='w-100'>
        <Map>
          {markers.map((marker) =>
            marker.type === 'Restaurantes' ? (
              <BusinessMarker key={marker.id} business={marker} />
            ) : (
              <ServiceMarker key={marker.id} service={marker} />
            )
          )}
          <LocationMarker />
        </Map>
      </div>
    </section>
  );
};

export default Presence;

import React from 'react';
import PropTypes from 'prop-types';

const NavItem = ({ name, isActive, onClick: handleClick, children }) => {
  const classes = isActive
    ? 'nav-link btn btn-link active'
    : 'nav-link btn btn-link';

  return (
      <button className={classes} onClick={() => handleClick(name)}>
        {children}
      </button>
  );
};

NavItem.propTypes = {
  name: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  onClick: PropTypes.func.isRequired,
};
NavItem.defaultProps = {
  isActive: false,
};

export default NavItem;

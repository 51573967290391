import React from 'react';
import { gradients } from '../../utils/gradients';
import Widget from './widgets/Widget';
import WidgetDetails from './widgets/WidgetDetails';
import WidgetIcon from './widgets/WidgetIcon';

const MonitoringDashboard = () => {
  return (
    <section className='dashboard__content'>
      <Widget>
        <WidgetIcon icon='bag' gradient={gradients.GRADIENT_DEEP_BLUE} />
        <WidgetDetails description='Pedidos pedientes' counter={5} />
      </Widget>
    </section>
  );
};

export default MonitoringDashboard;

import axios from 'axios';
import { apiCallFailed, apiCallInitiated, apiCallSucceed } from '../api';

axios.defaults.baseURL = 'http://alflay.com';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export const api =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type !== apiCallInitiated.type) return next(action);

    const { url, method, body, headers, onStart, onSuccess, onError } =
      action.payload;
    axios.interceptors.response.use(
      (response) => {
        /*console.log('response:'+JSON.stringify(response));*/
        return response.headers['content-type'].includes('application/json')
          ? response
          : Promise.reject(response);
      },
      (error) => {
        /*console.log('error:'+JSON.stringify(error));*/
        return Promise.reject(error);
      }
    );

    onStart && dispatch({ type: onStart });
    next(action);

    try {
      const requestAxios = await axios.request({
        url: url,
        method: method,
        headers:headers,
        data: body,
        /*transformResponse: [function (data) {
          // Do whatever you want to transform the data
          console.log(data);
          return data;
        }],*/
        validateStatus: function (status) {
          return status < 500; // Resolve only if the status code is less than 500
        }
      }); 
      
      const { data: responseData } = requestAxios;
      /*console.log(JSON.stringify(requestAxios));*/
/*       const { data: responseData } = await axios.request({
        url: url,
        method: method,
        headers:headers,
        data: body
      }); */ 
/*
      const { data: responseData } = await axios({
          method: method,
          url: url,
          headers:headers,
          data: body
      });
*/
      dispatch(apiCallSucceed(responseData));
      onSuccess && dispatch({ type: onSuccess, payload: responseData });
    } catch (error) {
      if(error.status < 500){
        dispatch(apiCallFailed(error.data));
        onError && dispatch({ type: onError, payload: error.data });
      }else{
        dispatch(apiCallFailed(error.message));
        onError && dispatch({ type: onError, payload: error.message });
      }
    }
  };

const GRADIENT_GREEN = 'gradient--green';
const GRADIENT_LIME = 'gradient--lime';
const GRADIENT_BLUE = 'gradient--blue';
const GRADIENT_PURPLE = 'gradient--purple';
const GRADIENT_BRUISE = 'gradient--bruise';
const GRADIENT_RED = 'gradient--red';
const GRADIENT_PREMIUM_DARK = 'gradient--premium-dark';
const GRADIENT_MORPHEUS_DEN = 'gradient--morpheus-den';
const GRADIENT_MALIBU_BEACH = 'gradient--malibu-beach';
const GRADIENT_NEW_LIFE = 'gradient--new-life';
const GRADIENT_PLUM_PALETTE = 'gradient--plum-palette';
const GRADIENT_EVERLASTING_SKY = 'gradient--everlasting-sky';
const GRADIENT_HAPPY_FISHER = 'gradient--happy-fisher';
const GRADIENT_ITMEO_BRANDING = 'gradient--itmeo-branding';
const GRADIENT_ZEUS_MIRACLE = 'gradient--zeus-miracle';
const GRADIENT_DEEP_BLUE = 'gradient--deep-blue';
const GRADIENT_MIXED_HOPES = 'gradient--mixed-hopes';
const GRADIENT_FLY_HIGH = 'gradient--fly-high';
const GRADIENT_SNOW_AGAIN = 'gradient--snow-again';
const GRADIENT_FEBRUARY_INK = 'gradient--february-ink';
const GRADIENT_GROWN_EARLY = 'gradient--grown-early';
const GRADIENT_SHARP_BLUE = 'gradient--sharp-blue';
const GRADIENT_LOVE_KISS = 'gradient--love-kiss';
const GRADIENT_PHOENIX_START = 'gradient--phoenix-start';
const GRADIENT_OCTOBER_SILENCE = 'gradient--october-silence';
const GRADIENT_MASS_PARTY = 'gradient--mass-party';
const GRADIENT_HIDDEN_JAGUAR = 'gradient--hidden-jaguar';
const GRADIENT_ARIELLE_S_SMILE = 'gradient--arielle-s-smile';
const GRADIENT_STAR_WINE = 'gradient--star-wine';
const GRADIENT_STRONG_BLISS = 'gradient--strong-bliss';

export const gradients = {
  GRADIENT_GREEN,
  GRADIENT_LIME,
  GRADIENT_BLUE,
  GRADIENT_PURPLE,
  GRADIENT_BRUISE,
  GRADIENT_RED,
  GRADIENT_PREMIUM_DARK,
  GRADIENT_MORPHEUS_DEN,
  GRADIENT_MALIBU_BEACH,
  GRADIENT_NEW_LIFE,
  GRADIENT_PLUM_PALETTE,
  GRADIENT_EVERLASTING_SKY,
  GRADIENT_HAPPY_FISHER,
  GRADIENT_ITMEO_BRANDING,
  GRADIENT_ZEUS_MIRACLE,
  GRADIENT_DEEP_BLUE,
  GRADIENT_MIXED_HOPES,
  GRADIENT_FLY_HIGH,
  GRADIENT_SNOW_AGAIN,
  GRADIENT_FEBRUARY_INK,
  GRADIENT_GROWN_EARLY,
  GRADIENT_SHARP_BLUE,
  GRADIENT_LOVE_KISS,
  GRADIENT_PHOENIX_START,
  GRADIENT_OCTOBER_SILENCE,
  GRADIENT_MASS_PARTY,
  GRADIENT_HIDDEN_JAGUAR,
  GRADIENT_ARIELLE_S_SMILE,
  GRADIENT_STAR_WINE,
  GRADIENT_STRONG_BLISS,
};

export default function validateBusiness(values) {
    let errors = {};
  
    if (!values.name.trim()) {
      errors.name = '¡Se requiere el nombre!';
    }
    // else if (!/^[A-Za-z]+/.test(values.name.trim())) {
    //   errors.name = 'Enter a valid name';
    // }
    if (!values.telephone) {
      errors.telephone = '¡Se requiere el número de teléfono!';
    } else if (!/\d+/.test(values.telephone)) {
      errors.telephone = '¡Entre un número de teléfono válido!';
    }
    return errors;
  }  
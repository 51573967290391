import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import MonitoringManagement from "./MonitoringManagement";
import BusinessesManagement from "./BusinessesManagement";
import DealerManagement from "./DealerManagement";
import NotFound from "../common/NotFound";

const Management = () => {
  const dispatch = useDispatch();
  let match = useRouteMatch();
  const business = useSelector((state) => state.entities.business);
  const auth = useSelector((state) => state.auth);

  return (
    <div className="py-5 container">
      <section className="py-5 management">
        <Switch>
          <Route exact path={`${match.path}`}>
            <MonitoringManagement />
          </Route>
          <Route exact path={`${match.path}/businesses`}>
            <BusinessesManagement user={auth.currentUser} />
          </Route>
          <Route exact path={`${match.path}/dealer`}>
            <DealerManagement />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </section>
    </div>
  );
};
export default Management;

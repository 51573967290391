import { combineReducers } from 'redux';
import categories from './categories';
import subcategories from './subcategories';
import business from './business';
import businesses from './businesses';
import orders from './orders';

export const entities = combineReducers({
  categories,
  subcategories,
  business,
  businesses,
  orders,
});

import React from 'react';
import PropTypes from 'prop-types';
import RMCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';

const CategoriesCarousel = (props) => {
  const {
    className,
    containerClassName,
    itemClassName,
    indicatorsListClassName,
    displayControls,
    displayIndicators,
    isCentered,
    isSwipeable,
    isDragable,
    isAutoPlayed,
    autoPlaySpeed,
    isInfinite,
    isKeyboardControlled,
    isServerSideRendered,
  } = props;

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const categories = [
    {
      id: 1,
      name: 'Entretenimiento',
      searchCount: 0,
      image: 'assets/img/uploads/categories/entertainment.jpeg',
      order: 0,
      description:
        '¿Estás aburrido? Explora nuestras propuestas. Tenemos películas, series, música y mucho más.',
      subCategories: [],
    },
    {
      id: 3,
      name: 'Restaurantes',
      searchCount: 0,
      image: 'assets/img/uploads/categories/restaurants.jpeg',
      order: 0,
      description:
        '¿Tienes hambre? Estás en el lugar correcto. Ordena tu comida a casa o reserva en tu restaurante favorito.',
      subCategories: [],
    },
    {
      id: 2,
      name: 'Servicios',
      searchCount: 0,
      image: 'assets/img/uploads/categories/services.jpeg',
      order: 0,
      description:
        '¿Buscas algún servicio? Encuentra profesionales para prácticamente cualquier cosa.',
      subCategories: [],
    },
    {
      id: 4,
      name: 'Tienda',
      searchCount: 0,
      image: 'assets/img/uploads/categories/shopping.jpeg',
      order: 0,
      description:
        '¿Quieres hacer un regalo? Da un vistazo a nuestras tiendas y chequea nuestros productos.',
      subCategories: [],
    },
  ];

  return (
    <section className='py-5 bg-light'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 m-auto text-center'>
            <h2 className='display-3'>Explora nuestras propuestas</h2>
            <p className='mb-5'>Tenemos justo lo que necesitas</p>
          </div>
        </div>
        <div className='row justify-content-center partner'>
          <RMCarousel
            arrows={displayControls}
            centerMode={isCentered}
            swipeable={isSwipeable}
            draggable={isDragable}
            showDots={displayIndicators}
            infinite={isInfinite}
            autoPlay={isAutoPlayed}
            autoPlaySpeed={autoPlaySpeed}
            ssr={isServerSideRendered}
            keyBoardControl={isKeyboardControlled}
            responsive={responsive}
            // autoPlay={this.props.deviceType !== 'mobile' ? true : false}
            //   customTransition='all .5'
            //   transitionDuration={500}
            className={className}
            containerClass={containerClassName}
            itemClass={itemClassName}
            dotListClass={indicatorsListClassName}
            //   removeArrowOnDeviceType={['tablet', 'mobile']}
            //   deviceType={this.props.deviceType}
          >
            {categories.map((category) => (
              <div key={category.id} className='card m-2 h-100'>
                <img src={category.image} className='card-img-top' alt='...' />
                <div className='card-body'>
                  <h5 className='card-title'>{category.name}</h5>
                  <p className='card-text'>{category.description}</p>
                </div>
                <div className='card-footer bg-white'>
                  <Link to='#' className='btn btn-primary w-100'>
                    Ver ofertas
                  </Link>
                </div>
              </div>
            ))}
          </RMCarousel>
        </div>
      </div>
    </section>
  );
};

CategoriesCarousel.propTypes = {
  displayControls: PropTypes.bool,
  displayIndicators: PropTypes.bool,
  isCentered: PropTypes.bool,
  isSwipeable: PropTypes.bool,
  isDragable: PropTypes.bool,
  isAutoPlayed: PropTypes.bool,
  autoPlaySpeed: PropTypes.number,
  isInfinite: PropTypes.bool,
  isKeyboardControlled: PropTypes.bool,
  isServerSideRendered: PropTypes.bool,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  indicatorsListClassName: PropTypes.string,
};

CategoriesCarousel.defaultProps = {
  displayControls: true,
  displayIndicators: true,
  isCentered: true,
  isSwipeable: true,
  isDragable: true,
  isAutoPlayed: true,
  autoPlaySpeed: 3000,
  isInfinite: true,
  isKeyboardControlled: true,
  isServerSideRendered: false,
  className: 'p-4',
  containerClassName: 'container pb-5',
  itemClassName: '',
  indicatorsListClassName: '',
};

export default CategoriesCarousel;

import { useSelector } from "react-redux";
import TimePicker from 'react-time-picker';
import useBusiness from "../../hooks/useBusiness";
import validate from "./validateBusiness";
import FileInput from "../common/FileInput";
// import MapSimple from '../management/MapSimple';
import PickerMap from "../management/PickerMap";
/*import LocationPickerComponent from "../common/LocationPickerComponent";*/

const AddBusiness = () => {
  const userSession = useSelector((state) => state.auth.currentUser);
  
  const initialState = {
    name: "",
    description: "",
    image: "",
    phone: "",
    address: "",
    latitude: 23.113441,
    longitude: -82.417775,
    openingTime: '10:00',
    closingTime: '23:00',
    logo: "http://alflay.com/ClientApp/assets/img/uploads/restaurants/Vernissage.jpg",
    ownerId: Number(userSession.userId),
    municipalityId: null,
    categoryId: null,
    isActiveStore: true,
    is24Hours: false,
    category: null,
    municipality: null,
    owner: userSession.name,
    products: [],
  };
  const submitForm = () => {
    console.log("Submit Business.");
  };
  const { handleChange, handleTimeChange, handleSubmit, values, errors } = useBusiness(
    initialState,
    submitForm,
    validate
  );

  return (
    <section className="form-container vertical-form add-category widget">
      <form className="form row g-3" onSubmit={handleSubmit}>
        <header className="header">
          <h2 className="heading">Agregar Negocio</h2>
        </header>
        <div className="col-12">
          <label className="form-label" htmlFor="name">
            Nombre
          </label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="name"
            id="name"
            placeholder="Introduzca el nombre de su negocio"
            value={values.name}
            onChange={handleChange}
          />
          {errors.name && <p>{errors.name}</p>}
        </div>
        <div className="col-12"></div>
        <div className="col-12">
          <label className="form-label" htmlFor="description">
            Descripción
          </label>
          <textarea
            type="text"
            className="form-control form-control-sm"
            name="description"
            id="description"
          />
        </div>
        {/* <div className='col-12'>
        <LocationPickerComponent />;
        </div> */}
        <div className="col-12">
          <label className="form-label" htmlFor="image">
            Foto descriptiva
          </label>
          <FileInput
            name="image"
            value={values.image}
            onChange={handleChange}
          />
        </div>
        <div className="col-12">
          <label className="form-label" htmlFor="phone">
            Teléfono
          </label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="phone"
            id="phone"
            placeholder="Introduzca el número de teléfono de su negocio"
            value={values.phone}
            onChange={handleChange}
          />
          {errors.phone && <p>{errors.phone}</p>}
        </div>
        <div className="col-12">
          <TimePicker name="openingTime" clockIcon={null} disableClock format='hh:mm a' onChange={ (value) => { handleTimeChange({name:'openingTime', value}); } } value={values.openingTime} />
        </div>
        <div className="col-12">
          <label className="form-label" htmlFor="address">
            Dirección
          </label>
          <input
            type="text"
            className="form-control form-control-sm"
            name="address"
            id="address"
            placeholder="Introduzca la dirección de su negocio"
            value={values.address}
            onChange={handleChange}
          />
          {errors.address && <p>{errors.address}</p>}
        </div>
        <div className="col-12">
          {/* <MapSimple/> */}
          <PickerMap></PickerMap>
        </div>
        <footer className="footer">
          <button
            className="btn btn-outline-primary btn-sm"
            type="submit"
            onClick={handleSubmit}
          >
            <i className="bi bi-check2-square me-2"></i>Agregar
          </button>
          <button className="btn btn-outline-danger btn-sm" type="reset">
            <i className="bi bi-x-square me-2"></i>Cancelar
          </button>
        </footer>
      </form>
    </section>
  );
};

export default AddBusiness;

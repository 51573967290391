import React from 'react';
import PropTypes from 'prop-types';

const TableHeader = ({ columns, sortColumn, onSort: handleSort }) => {
  const handleClick = (path) => {
    if (!path) return;
    sortColumn.path === path
      ? (sortColumn.order = sortColumn.order === 'asc' ? 'desc' : 'asc')
      : (sortColumn = { ...sortColumn, path, order: 'asc' });

    handleSort(sortColumn);
  };

  const renderSortIcon = (column) => {
    return column.path !== sortColumn.path ? null : sortColumn.order ===
      'asc' ? (
      <i className='bi bi-sort-down'></i>
    ) : (
      <i className='bi bi-sort-up'></i>
    );
  };

  return (
    <thead>
      <tr>
        {columns.map((column) => (
          <th
            key={column.path ?? column.key}
            onClick={() => handleClick(column.path)}>
            {column.label} {renderSortIcon(column)}
          </th>
        ))}
      </tr>
    </thead>
  );
};

TableHeader.propTypes = {
  columns: PropTypes.array.isRequired,
  sortColumn: PropTypes.object.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default TableHeader;

import axios from 'axios';
import debounce from 'lodash.debounce';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Cover from './Cover';
import SearchBar from './SearchBar';

const { REACT_APP_ACCESS_TOKEN } = process.env;
const selectPlaceholder = { id: -1024, name: '¿De qué categoría?' };

const Landing = () => {
  const categories = useSelector((state) => state.entities.categories);

  const [criteria, setCriteria] = useState('');
  const [location, setLocation] = useState('');
  const [dataList, setDataList] = useState(null);
  const [category, setCategory] = useState(selectPlaceholder.id);
  const [categoriesList, setCategoriesList] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    setCategoriesList([
      selectPlaceholder,
      ...categories.list.map((category) => categories.byId[category]),
    ]);
  }, [categories]);

  const handleCriteriaChange = (e) => {
    setError(null);

    setCriteria(e.target.value);
  };

  const handleLocationChange = (e) => {
    const value = e.target.value;
    setError(null);
    setLocation(value);
    debounce(fetchPlacePrediction, 500, {
      leading: true,
      trailing: true,
    })(value);
  };

  const handleCategoryChange = (e) => {
    setError(null);
    setCategoriesList(
      categoriesList.map((category) =>
        category.id === selectPlaceholder.id ? selectPlaceholder : category
      )
    );
    setCategory(+e.target.value);
  };

  const fetchPlacePrediction = async (value) => {
    try {
      const { data } = await axios.get(
        `https://api.locationiq.com/v1/autocomplete.php?key=${REACT_APP_ACCESS_TOKEN}&q=${value}&tag=place:*&countrycodes=cu`
      );
      setDataList(data);
    } catch (error) {}
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formErrors = {};

    if (!criteria) formErrors.criteria = 'Inserta tu búsqueda';

    if (!location) formErrors.location = 'Inserta tu ubicación';

    if (category === selectPlaceholder.id) {
      const errorMessage = 'Elige la categoría';

      setCategoriesList(
        categoriesList.map((category) =>
          category.name === selectPlaceholder.name
            ? { ...category, name: errorMessage }
            : category
        )
      );
      formErrors.category = errorMessage;
    }

    if (Object.keys(formErrors).length > 0) return setError(formErrors);

    console.log('Searching :>> ', criteria, location, category);
  };

  return (
    <div className='text-center'>
      <Cover>
        <div className='container landing__content'>
          <div className='row justify-content-center'>
            <div className='col-xl-10 '>
              <img
                className='d-none d-lg-inline align-self-center'
                src='/logo192.png'
                alt=''
              />
              <h1 className='display-1 text-white text-shadow-lg'>
                Bienvenidos a AlFlay
              </h1>
              <h2 className='text-white text-shadow-lg'>
                Todo lo que necesitas, al alcance de tu mano
              </h2>
              <SearchBar
                criteria={criteria}
                location={location}
                category={category}
                error={error}
                dataList={dataList}
                categories={categoriesList}
                selectPlaceholder={selectPlaceholder}
                onCriteriaChange={handleCriteriaChange}
                onLocationChange={handleLocationChange}
                onCategoryChange={handleCategoryChange}
                onSubmit={handleSubmit}
              />
            </div>
          </div>
        </div>
      </Cover>
    </div>
  );
};

export default Landing;

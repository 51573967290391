import React from 'react';
import { useSelector } from 'react-redux';
import AddBusiness from '../admin/AddBusiness'

const MonitoringManagement = () => {
  const business = useSelector((state) => state.entities.businesses);
  console.log(business);

  return (
    <section className='content'>
      <AddBusiness/>
    </section>
  );
};

export default MonitoringManagement;
import React from "react";
// import { Redirect } from 'react-router-dom';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const Profile = () => {
  //const { currentUser } = useSelector((state) => state.auth);
  const authReducer = useSelector((state) => state.auth);
  const { currentUser } = authReducer;
 
  return (
    <div className="mt-5 py-5 container">
      <header className="jumbotron">
        <h3>
          <strong className="text-capitalize">{currentUser.name}</strong> Profile
        </h3>
      </header>
      <div className="row">
        <div className="offset-md-8 col-md-4"><Link className="btn btn-primary" to="/management/businesses" role="button">Management</Link></div>
      </div>
      {/* <p>
        <strong>Token:</strong> {authReducer.authToken.substring(0, 10)} ...{" "}
        {authReducer.authToken.substr(authReducer.authToken.length - 10)}
      </p>
       <p>
        <strong>Id:</strong> {currentUser.userId}
      </p> */}
      <p>
        <strong>Email:</strong> 
        {currentUser.email}
      </p>
      <strong>Authorities:</strong>
      <ul className="list-unstyled">
        {currentUser.roles &&
          currentUser.roles.map((role, index) => <li key={index}><i className='bi bi-check2-circle'></i> {role}</li>)}
      </ul>
    </div>
  );
};
export default Profile;
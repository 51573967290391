import { createSlice } from '@reduxjs/toolkit';
import { apiCallInitiated } from './api';

const url = '/subcategories';

const subcategoriesSlice = createSlice({
  name: 'subcategories',
  initialState: {
    loading: false,
    byId: {},
    list: [],
  },
  reducers: {
    subcategoriesApiCallInitiated: (subcategories) => {
      subcategories.loading = true;
    },
    subcategoriesApiCallFailed: (subcategories) => {
      subcategories.loading = false;
    },
    subcategoriesLoaded: (subcategories, action) => {
      const subcategoriesList = action.payload;

      subcategoriesList.forEach((subcategory) => {
        subcategories.byId[subcategory.id] = subcategory;
        !subcategories.list.includes(subcategory.id) &&
          subcategories.list.push(subcategory.id);
      });

      subcategories.loading = false;
    },
    categoryAdded: (subcategories, action) => {
      const newSubcategory = action.payload;
      subcategories.byId[newSubcategory.id] = newSubcategory;
      subcategories.push(newSubcategory.id);
    },
  },
});

export default subcategoriesSlice.reducer;

const {
  subcategoriesApiCallInitiated,
  subcategoriesApiCallFailed,
  subcategoriesLoaded,
  categoryAdded,
} = subcategoriesSlice.actions;

export const loadSubcategories = () =>
  apiCallInitiated({
    url,
    method: 'get',
    onStart: subcategoriesApiCallInitiated.type,
    onSuccess: subcategoriesLoaded.type,
    onError: subcategoriesApiCallFailed.type,
  });

export const addSubcategory = () =>
  apiCallInitiated({
    url,
    method: 'post',
    onStart: subcategoriesApiCallInitiated.type,
    onSuccess: categoryAdded.type,
    onError: subcategoriesApiCallFailed.type,
    headers: {
      Accept: '*/*',
      Authorization: 'Basic',
      // 'Content-Type': 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });

import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import Sidebar from './Sidebar';
import MonitoringDashboard from './MonitoringDashboard';
import MetricsDashboard from './MetricsDashboard';
import HistoryDashboard from './HistoryDashboard';
import MapDashboard from './MapDashboard';
import OrdersDashboard from './OrdersDashboard';
import InvoicesDashboard from './InvoicesDashboard';
import CategoriesDashboard from './CategoriesDashboard';
import SubcategoriesDashboard from './SubcategoriesDashboard';
import ProductsDashboard from './ProductsDashboard';
import GenericsDashboard from './GenericDashboard';
import CustomersDashboard from './CustomersDashboard';
import SegmentsDashboard from './SegmentsDashboard';
import BusinessesDashboard from './BusinessesDashboard';
import CouriersDashboard from './CouriersDashboard';
import ConfigDashboard from './ConfigDashboard';
import UsersDashboard from './UsersDashboard';
import { useDispatch, useSelector } from 'react-redux';
import { loadCategories } from '../../store/categories';

const Dashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadCategories());
  }, [dispatch]);

  let match = useRouteMatch();
  const categories = useSelector((state) => state.entities.categories);

  return (
    <section className='dashboard'>
      <Sidebar />
      <Switch>
        <Route exact path={`${match.path}`}>
          <MonitoringDashboard />
        </Route>
        <Route exact path={`${match.path}/metrics`}>
          <MetricsDashboard />
        </Route>
        <Route exact path={`${match.path}/history`}>
          <HistoryDashboard />
        </Route>
        <Route path={`${match.path}/map`}>
          <MapDashboard />
        </Route>
        <Route path={`${match.path}/orders`}>
          <OrdersDashboard />
        </Route>
        <Route path={`${match.path}/invoices`}>
          <InvoicesDashboard />
        </Route>
        <Route path={`${match.path}/categories`}>
          <CategoriesDashboard />
        </Route>
        <Route path={`${match.path}/subcategories`}>
          <SubcategoriesDashboard />
        </Route>
        <Route path={`${match.path}/products`}>
          <ProductsDashboard />
        </Route>
        {categories.list.map((category) => (
          <Route key={category} path={`${match.path}/${category}`}>
            <GenericsDashboard />
          </Route>
        ))}
        <Route path={`${match.path}/customers`}>
          <CustomersDashboard />
        </Route>
        <Route path={`${match.path}/segments`}>
          <SegmentsDashboard />
        </Route>
        <Route path={`${match.path}/businesses`}>
          <BusinessesDashboard />
        </Route>
        <Route path={`${match.path}/couriers`}>
          <CouriersDashboard />
        </Route>
        <Route path={`${match.path}/config`}>
          <ConfigDashboard />
        </Route>
        <Route path={`${match.path}/users`}>
          <UsersDashboard />
        </Route>
      </Switch>
    </section>
  );
};

export default Dashboard;

import React, { useEffect, useState } from 'react';
import { orderBy } from 'lodash';
// import { useSelector } from 'react-redux';
import Table from '../common/Table';
import Pagination from '../common/Pagination';
import { paginate } from '../../utils/paginate';

const ProductsTable = ({products}) => {
  //const categories = useSelector((state) => state.entities.categories);
  //console.log(products);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(25);
  const [sortColumn, setSortColumn] = useState({ path: 'id', order: 'asc' });
  const [list, setList] = useState(
    ...products.map((product) => products.byId[product])
  );

  useEffect(() => {
    setList(
      paginate(
        orderBy(
          products.map((product) => products.byId[product]),
          sortColumn.path,
          sortColumn.order
        ),
        currentPage,
        pageSize
      )
    );
  }, [products, sortColumn, currentPage, pageSize]);

  const columns = [
    { path: 'id', label: 'Id' },
    { path: 'name', label: 'Producto' },
    { path: 'amount', label: 'Precio' },
    { key: 'image' },
    {
      key: 'edit',
      content: (product) => (
        <div className='d-flex flex-row justify-content-end align-items-center'>
          <button
            className='btn btn-outline-warning btn-sm me-1'
            onClick={() => console.log(`${product.name} clicked`)}>
            <i className='bi bi-pencil'></i>
          </button>
          <button
            className='btn btn-outline-danger btn-sm'
            onClick={() => console.log(`${product.name} clicked`)}>
            <i className='bi bi-x'></i>
          </button>
        </div>
      ),
    },
  ];

  const handleSort = (sortCriteria) => {
    setSortColumn(sortCriteria);
    setList(orderBy(list, sortColumn.path, sortColumn.order));
  };

  return (
    <div className='container-md'>
      <h4 className='card-header-title'>Productos</h4>
      <header className='widget__header'>
        
        <Pagination
          itemsCount={products.length}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
        <button className='btn btn-sm btn-white'>Exportar</button>
      </header>
      <div className='table-container'>
        <Table
          columns={columns}
          sortColumn={sortColumn}
          onSort={handleSort}
          data={list}
        />
      </div>
    </div>
  );
};

export default ProductsTable;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import auth, { login } from "../../store/auth";
/* import axios from 'axios';
import { setUserSession } from '../common/User';
import PropTypes from 'prop-types'; */

const LoginForm = (props) => {
  /*  const usernamefield = useFormInput("");
  const passwordfield = useFormInput(""); */
  const [inputfields, setinputfields] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState(null);
  /*const [loading, setLoading] = useState(true);*/
  const authReducer = useSelector((state) => state.auth);

  const [eye, seteye] = useState(true);
  const [password, setpassword] = useState("password");

  const dispatch = useDispatch();
  const inputEvent = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setinputfields((lastValue) => {
      return {
        ...lastValue,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if(!e.target.reportValidity()){
      return e.target.reportValidity();
    }
    setError(null);
    /*setLoading(true);*/
    auth.loading = true;
    dispatch(login(inputfields));
    /*setLoading(false);*/
    auth.loading = false;
  }

  const handleLogin = async (e) => {
    //console.log("handleLogin");
  };

  const Eye = () => {
    if (password === "password") {
      setpassword("text");
      seteye(false);
    } else {
      setpassword("password");
      seteye(true);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="header">
            <h1 className="display-4 fw-bold lh-1 mb-3">Bienvenido a AlFlay</h1>
            <p className="col-lg-10 fs-4">
              Somos la solución que hará su vida más fácil. Con nosotros podrá
              encargar todo lo que crea necesario y le llegará AlFlay
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <form className="was-validated" onSubmit={handleSubmit}>
            <div className="">
              {authReducer.error && (
                <>
                  <small style={{ color: "red" }}>{authReducer.error}</small>
                  <br />
                </>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="username" className="form-label">
                Usuario
              </label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="bi bi-envelope-fill"></i>
                  </span>
                </div>
                <input
                  /* {...usernamefield} */
                  value={inputfields.username}
                  onChange={inputEvent}
                  type="text"
                  className="form-control"
                  id="username"
                  autoComplete="current-username"
                  placeholder="Enter your email"
                  name="username"
                  /* validations={[required]} */
                  required
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                />
              </div>
              <small id="emailHelp" className="text-muted form-text">
                Nunca compartiremos su correo electrónico con nadie más.
              </small>
              {error && (
                <>
                  <small style={{ color: "red" }}>{error}</small>
                  <br />
                </>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="bi bi-lock-fill"></i>
                  </span>
                </div>
                <input
                  value={inputfields.password}
                  onChange={inputEvent}
                  type={password}
                  className="form-control"
                  id="password"
                  autoComplete="current-password"
                  placeholder="Enter your password"
                  name="password"
                  required
                />
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={Eye}
                >
                  <i className={`bi ${eye ? "bi-eye-slash" : "bi-eye"}`}></i>
                </button>
              </div>
            </div>
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="remember-me"
              />
              <label className="form-check-label" htmlFor="remember-me">
                Recordarme en este equipo
              </label>
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleLogin}
              disabled={authReducer.loading}
            >
              {authReducer.loading ? (<><span className="spinner-border spinner-border-sm"></span> Loading...</>) : "Login"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

LoginForm.propTypes = {};

export default LoginForm;

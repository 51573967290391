import React from 'react';
import CategoriesWidget from './widgets/CategoriesWidget';

const MetricsDashboard = () => {
  return (
    <section>
      <CategoriesWidget />
    </section>
  );
};

export default MetricsDashboard;

import { configureStore } from '@reduxjs/toolkit';
import { api } from './middleware/api';
import { toastNotifications } from './middleware/toast';
import { reducer } from './reducer';

// convert object to string and store in localStorage
export function saveToLocalStorage(state) {
  console.log('saveToLocalStorage');
  try {
    const serialisedState = JSON.stringify(state);
    localStorage.setItem("alflay.persistantState", serialisedState);
  } catch (e) {
    console.warn(e);
  }
}

// load string from localStarage and convert into an Object
// invalid output must be undefined
export function loadFromLocalStorage() {
  console.log('loadFromLocalStorage');
  try {
    const serialisedState = localStorage.getItem("alflay.persistantState");
    if (serialisedState === null) return undefined;
    return JSON.parse(serialisedState);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
}

const store = () =>
  configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(api, toastNotifications, ),
  });

export default store;
import { createSlice } from "@reduxjs/toolkit";
import { apiCallInitiated } from "./api";
import { getToken } from '../components/auth/User';

const urlLoad = "/api/Stores/";
const urlNew = "/api/Stores";

const businessesSlice = createSlice({
  name: "businesses",
  initialState: {
    loading: false,
    byId: {},
    list: [],
  },
  reducers: {
    businessesApiCallInitiated: (businesses) => {
      businesses.loading = true;
    },
    businessesApiCallFailed: (businesses) => {
      businesses.loading = false;
    },
    businessesLoaded: (businesses, action) => {
      const businessesList = action.payload.value;

      businessesList.forEach((business) => {
        businesses.byId[business.id] = business;
        !businesses.list.includes(business.id) &&
          businesses.list.push(business.id);
      });

      businesses.loading = false;
    },
    businessAdded: (businesses, action) => {
      const newBusiness = action.payload;
      businesses.byId[newBusiness.id] = newBusiness;
      businesses.push(newBusiness.id);
    },
  },
});

export default businessesSlice.reducer;

const {
  businessesApiCallInitiated,
  businessesApiCallFailed,
  businessesLoaded,
  businessAdded,
} = businessesSlice.actions;

const equalized = (business) => {
  const {
    name,
    phone,
    address,
    ownerId,
    /* latitude,
    longitude,
    openingTime,
    closingTime,
    logo,
    storeRate,
    ownerId,
    municipalityId,
    categoryId,
    isActiveStore,
    is24Hours,
    category,
    municipality,
    owner,
    products, */
  } = business;
  return {
    storeName: name,
    phoneNumber: Number(phone),
    storeAddres: address,
    ownerId: ownerId,
   /* storeLatitude: latitude|null,
    storeLongitude: longitude|null,
    openingTime: openingTime|null,
    closingTime: closingTime|null,
    activateDiscount: false,
    elaborationTime: 15,
    logo: logo|null,
    storeRate: storeRate|null,
    ownerId: ownerId,
    municipalityId: municipalityId|null,
    categoryId: categoryId|null,
    isActiveStore: isActiveStore|false,
    isOpenToday: false,
    canBeBooked: true,
    canBePickedUp: true,
    is24Hours: is24Hours|false,
    imagesFolderURL: null,
    generalDiscount: 0,
    category: category|null,
    municipality: municipality|null,
    owner: owner|null,
    products: products|[],
    storeCloseDay: [],*/
  };
};

export const loadBusinesses = () =>
  apiCallInitiated({
    urlLoad,
    method: "get",
    onStart: businessesApiCallInitiated.type,
    onSuccess: businessesLoaded.type,
    onError: businessesApiCallFailed.type,
  });

export const addBusiness = (business) =>
  apiCallInitiated({
    url: urlNew,
    method: "post",
    onStart: businessesApiCallInitiated.type,
    onSuccess: businessAdded.type,
    onError: businessesApiCallFailed.type,
    headers: {
      Accept: "*/*",
      // 'Content-Type': 'application/json',
      //"Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      Authorization: 'Bearer ' + getToken()
    },
    body: equalized(business),
  });
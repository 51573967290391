import { createSlice } from "@reduxjs/toolkit";
import { apiCallInitiated } from "./api";

const url = { load: "/Products/by-store/1" };

const productsSlice = createSlice({
  name: "products",
  initialState: {
    loading: false,
    byId: {},
    list: [],
  },
  reducers: {
    productsApiCallInitiated: (products) => {
      products.loading = true;
    },
    productsApiCallFailed: (products) => {
      products.loading = false;
    },
    productsLoaded: (products, action) => {
      const productsList = action.payload;

      productsList.forEach((product) => {
        products.byId[product.id] = product;
        !products.list.includes(product.id) && products.list.push(product.id);
      });

      products.loading = false;
    },
    productsAdded: (products, action) => {
      const newProducts = action.payload;
      products.byId[newProducts.id] = newProducts;
      products.push(newProducts.id);
    },
  },
});

export default productsSlice.reducer;

const {
  productsApiCallInitiated,
  productsApiCallFailed,
  productsLoaded,
  productsAdded,
} = productsSlice.actions;

export const equalized = (product) => {
  return {
    ProductName: "Prueba Yuri",
    Price: 100.0,
    ProductDescription: "Yuri probando la insercion",
    ProductPicture: "url/foto",
    Rating: 0,
    SearchCount: 0,
    IsOnSale: true,
    SubCategoryId: 1022,
    StoreId: 1,
  };
};
export const loadProducts = () =>
  apiCallInitiated({
    url,
    method: "get",
    onStart: productsApiCallInitiated.type,
    onSuccess: productsLoaded.type,
    onError: productsApiCallFailed.type,
  });

export const addProducts = (product) =>
  apiCallInitiated({
    url,
    method: "post",
    onStart: productsApiCallInitiated.type,
    onSuccess: productsAdded.type,
    onError: productsApiCallFailed.type,
    headers: {
      Accept: "*/*",
      Authorization: "Basic",
      // 'Content-Type': 'application/json',
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    },
    body: equalized(product)
  });

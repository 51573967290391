export default function validateInfo(values) {
    let errors = {};
  
    if (!values.username.trim()) {
      errors.username = '¡Se requiere el Usuario!';
    }
    // else if (!/^[A-Za-z]+/.test(values.name.trim())) {
    //   errors.name = 'Enter a valid name';
    // }
    if (!values.telephone) {
      errors.telephone = '¡Se requiere el número de teléfono!';
    } else if (!/\d+/.test(values.telephone)) {
      errors.telephone = '¡Entre un número de teléfono válido!';
    }
    if (!values.email) {
      errors.email = '¡Se requiere el correo de electrónico!';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = '¡Entre una dirección de correo electrónico válido!';
    }
    if (!values.password) {
      errors.password = '!Se requiere una contraseña!';
    } else if (values.password.length < 6) {
      errors.password = '!Se necesitan más de 6 caracteres en la contraseña!';
    }
  
    if (!values.password2) {
      errors.password2 = '!Se requiere confirmar la contraseña!';
    } else if (values.password2 !== values.password) {
      errors.password2 = '!La contraseña no coincide!';
    }
    return errors;
  }  
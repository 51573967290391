import { createSlice } from '@reduxjs/toolkit';
import jwt from "jwt-decode";
import { apiCallInitiated } from './api';
import { User, getToken, setUserSession, removeUserSession } from '../components/auth/User';

const urlLogin = '/api/Login/sign-up';
const urlOwner = '/api/User/update-role/1052/3';
//const urlLogout = '/api/Login/sign-out';
let user = User();
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: false,
    currentUser: user ? user: {
      userId: null,
      name: null,
      email: null,
      roles: []
    },
    loggedIn: false,
    owner: false,
    authToken: getToken(),
    error:[]
  },
  reducers: {
    authApiCallInitiated: (auth) => {
      //console.log('Initiated:'+auth.type);
      auth.loading = true;
      auth.error=[];
    },
    authApiCallSuccessed: (auth, action) => {
      //console.log('Successed:'+auth.type);
      auth.loading = false;
      const { token } = action.payload.value;
      auth.authToken = token;
      const parsedAccessToken = jwt(token); 
      //console.log(parsedAccessToken);
      auth.currentUser = {
        userId: parsedAccessToken.UserID,
        name: parsedAccessToken.given_name,
        email: parsedAccessToken.email,
        roles: JSON.parse(parsedAccessToken.UserRoll)
      }
      setUserSession(auth.authToken, auth.currentUser);
      //console.log('respuesta:'+JSON.stringify(action.payload));
      //this.loginUser(auth, action);
    },
    ownerUser: (auth, action) =>{ /*console.log(action.payload);*/ auth.owner = true; },
    loginUser: (auth, action) => {
      auth.currentUser = action.payload;
      /* console.log('loginUser:'+action.type+' auth:'+auth.currentUser); */
      this.getUser(auth, action);
    },
    loginUserFailed: (auth, action) => { auth.loading = false; auth.error = action.payload;  /* console.log(action); console.log('loginUserFailed:'+action.type+' auth:'+JSON.stringify(auth)); */ },
    getUser: (auth, action) => {  /* console.log('getUser'); console.log(action.payload.value); */ },
    logoutUser: (auth, action) => {auth.authToken = null; removeUserSession(); auth.currentUser = {}; /* console.log('logoutUser: '+JSON.stringify(auth)); console.log('logoutUser:'+auth.initialState); */ },
  },
});

export default authSlice.reducer;

export const {authApiCallInitiated, authApiCallSuccessed, loginUser, ownerUser, loginUserFailed, getUser, logoutUser } = authSlice.actions;

export const equalized = (credencial) => {
  const { username, password } = credencial;
  return {
    UserName: username,
    PasswordHash: password
  }
}

export const login = (credencial) =>
  apiCallInitiated({
    url: urlLogin,
    method: 'post',
    onStart: authApiCallInitiated.type,
    onSuccess: authApiCallSuccessed.type,
    onError: loginUserFailed.type,
    headers: {
      Accept: '*/*',
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Basic'
    },
    body: equalized(credencial)          
  });

export const owner = () =>
  apiCallInitiated({
    url: urlOwner,
    method: 'PATCH',
    onStart: authApiCallInitiated.type,
    onSuccess: ownerUser.type,
    onError: loginUserFailed.type,
    headers: {
      Accept: '*/*',
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Basic'
    },          
  });

  export function checkAuthentication() {
    return async () => {
      const auth = await getToken();
      console.log('checkAuthentication:' + JSON.stringify(auth));
      return (auth !== null);
    };
  }

export const logout = async (dispatch) => 
{ console.log('logout:'); console.log(logoutUser(0)); /*await dispatch(logoutUser());*/ }
  // apiCallInitiated({
  //   url: urlLogout,
  //   method: 'post',
  //   onStart: authApiCallInitiated.type,
  //   onSuccess: logoutUser.type,
  //   onError: loginUserFailed.type,
  //   headers: {
  //     Accept: '*/*',
  //     "Content-Type": "application/json",
  //     'Access-Control-Allow-Origin': '*',
  //     Authorization: 'Bearer ' + getToken()
  //     //Authorization: 'Basic'
  //   }        
  // });

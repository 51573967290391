import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import SidebarCollapsible from '../common/SidebarCollapsible';
import SidebarLink from '../common/SidebarLink';

const Sidebar = () => {
  const match = useRouteMatch();
  const categories = useSelector((state) => state.entities.categories);

  return (
    <aside className='sidebar h-100 d-flex flex-column flex-shrink-0 p-3 bg-white'>
      <ul className='nav nav-pills flex-column mb-auto'>
        <SidebarLink
          to={`${match.url}`}
          label='Panel de control'
          icon='columns-gap'
          exact
        />
        <SidebarLink
          to={`${match.url}/metrics`}
          label='Indicadores'
          icon='speedometer'
        />
        <SidebarLink
          to={`${match.url}/history`}
          label='Histórico'
          icon='clock-history'
        />
        <SidebarLink to={`${match.url}/map`} label='Mapa' icon='map' />
        <SidebarCollapsible label='Ventas' icon='cash-coin'>
          <SidebarLink to={`${match.url}/orders`} label='Órdenes' icon='bag' />
          <SidebarLink
            to={`${match.url}/invoices`}
            label='Facturas'
            icon='receipt-cutoff'
          />
        </SidebarCollapsible>
        <SidebarCollapsible label='Catálogo' icon='collection'>
          <SidebarLink
            to={`${match.url}/categories`}
            label='Categorías'
            icon='tag'
          />
          <SidebarLink
            to={`${match.url}/subcategories`}
            label='Subcategorías'
            icon='tags'
          />
          <SidebarLink
            to={`${match.url}/products`}
            label='Productos'
            icon='box-seam'
          />
          {categories?.list.map((category) => (
            <SidebarLink
              key={category}
              to={`${match.url}/${category}`}
              label={categories.byId[category].name}
              icon='bookmarks'
            />
          ))}
        </SidebarCollapsible>
        <SidebarCollapsible label='Clientes' icon='person-circle'>
          <SidebarLink
            to={`${match.url}/customers`}
            label='Clientes'
            icon='people'
          />
          <SidebarLink
            to={`${match.url}/segments`}
            label='Segmentos'
            icon='x-diamond'
          />
        </SidebarCollapsible>
        <SidebarCollapsible label='Asociados' icon='node-plus'>
          <SidebarLink
            to={`${match.url}/businesses`}
            label='Negocios'
            icon='shop'
          />
          <SidebarLink
            to={`${match.url}/couriers`}
            label='Mensajeros'
            icon='bicycle'
          />
        </SidebarCollapsible>
        <SidebarCollapsible label='Administración' icon='shield-lock'>
          <SidebarLink
            to={`${match.url}/config`}
            label='Configuración'
            icon='gear'
          />
          <SidebarLink
            to={`${match.url}/users`}
            label='Usuarios'
            icon='person-bounding-box'
          />
        </SidebarCollapsible>
      </ul>
    </aside>
  );
};

export default Sidebar;

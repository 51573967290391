import React from 'react';
import PropTypes from 'prop-types';

const WidgetDetails = ({ description, counter, direction }) => {
  return (
    <div className={`me-4 widget__details widget__details--${direction}`}>
      <p className='widget__description text-nowrap'>{description}</p>
      <h2 className='widget__counter'>{counter}</h2>
    </div>
  );
};

WidgetDetails.propTypes = {
  counter: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['row', 'column']),
};

WidgetDetails.defaultProps = {
  direction: 'column',
};

export default WidgetDetails;

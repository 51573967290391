import React, { useEffect, useState } from 'react';
import ReactLeafletDriftMarker from 'react-leaflet-drift-marker';
import { Popup, useMapEvents } from 'react-leaflet';
import { currentLocationIcon } from '../../utils/markerIcons';
import { toast } from 'react-toastify';

const LocationMarker = () => {
  const [position, setPosition] = useState(null);

  const map = useMapEvents({
    locationfound(e) {
      setPosition(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
    },
    locationerror(e) {
      toast.error('No pudimos determinar su ubicación');
    },
  });

  useEffect(() => {
    map.locate({ setView: true });
  }, [map]);

  return position === null ? null : (
    <ReactLeafletDriftMarker
      position={position}
      icon={currentLocationIcon}
      duration={500}>
      <Popup>You are here</Popup>
    </ReactLeafletDriftMarker>
  );
};

export default LocationMarker;

import React from 'react';
import CountUp from 'react-countup';
import { Background, Parallax } from 'react-parallax';
import ReactVisibilitySensor from 'react-visibility-sensor';

const Statistics = () => {
  return (
    <Parallax blur={2} strength={300}>
      <Background>
        <img
          className='parallax-image'
          sizes='(max-width: 3840px) 100vw, 3840px'
          srcSet={`${
            require('../../assets/img/parallax/parallax-background_w_190.jpg')
              .default
          } 190w, ${
            require('../../assets/img/parallax/parallax-background_w_726.jpg')
              .default
          } 726w, ${
            require('../../assets/img/parallax/parallax-background_w_1083.jpg')
              .default
          } 1083w, ${
            require('../../assets/img/parallax/parallax-background_w_1362.jpg')
              .default
          } 1362w, ${
            require('../../assets/img/parallax/parallax-background_w_1618.jpg')
              .default
          } 1618w, ${
            require('../../assets/img/parallax/parallax-background_w_1854.jpg')
              .default
          } 1854w, ${
            require('../../assets/img/parallax/parallax-background_w_2064.jpg')
              .default
          } 2064w, ${
            require('../../assets/img/parallax/parallax-background_w_2368.jpg')
              .default
          } 2368w, ${
            require('../../assets/img/parallax/parallax-background_w_2575.jpg')
              .default
          } 2575w, ${
            require('../../assets/img/parallax/parallax-background_w_2792.jpg')
              .default
          } 2792w, ${
            require('../../assets/img/parallax/parallax-background_w_2764.jpg')
              .default
          } 2764w, ${
            require('../../assets/img/parallax/parallax-background_w_3421.jpg')
              .default
          } 3421w, ${
            require('../../assets/img/parallax/parallax-background_w_3554.jpg')
              .default
          } 3554w, ${
            require('../../assets/img/parallax/parallax-background_w_3692.jpg')
              .default
          } 3692w, ${
            require('../../assets/img/parallax/parallax-background_w_3835.jpg')
              .default
          } 3835w, ${
            require('../../assets/img/parallax/parallax-background_w_3840.jpg')
              .default
          } 3840w`}
          src={
            require('../../assets/img/parallax/parallax-background_w_3840.jpg')
              .default
          }
          alt=''
        />
      </Background>
      <section className='py-5 my-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8 m-auto text-center'>
              <h2 className='display-3 text-white'>¿Por qué elegir AlFlay?</h2>
              <p className='mb-5 text-white'>
                Nuestros números hablan por nosotros. Elígenos y descubre la
                respuesta. ¡No te arrepentirás!
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-3 col-6 mt-3 mt-lg-0'>
              <div className='text-white text-shadow-sm'>
                <ReactVisibilitySensor partialVisibility>
                  {({ isVisible }) => (
                    <div className='d-flex flex-column justify-content-between align-items-center'>
                      <i className='bi-person fs-1'></i>
                      {isVisible ? (
                        <CountUp
                          className='text-sans-serif font-weight-bold display-4'
                          end={8000}
                          duration={5}
                        />
                      ) : null}
                      <p className='badge bg-dark bg-opacity-75'>Usuarios</p>
                    </div>
                  )}
                </ReactVisibilitySensor>
              </div>
            </div>
            <div className='col-lg-3 col-6 mt-3 mt-lg-0'>
              <div className='text-white text-shadow-sm'>
                <ReactVisibilitySensor partialVisibility>
                  {({ isVisible }) => (
                    <div className='d-flex flex-column justify-content-between align-items-center'>
                      <i className='bi-shop fs-1'></i>
                      {isVisible ? (
                        <CountUp
                          className='text-sans-serif font-weight-bold display-4'
                          end={8000}
                          duration={5}
                        />
                      ) : null}
                      <p className='badge bg-dark bg-opacity-75'>
                        Restaurantes
                      </p>
                    </div>
                  )}
                </ReactVisibilitySensor>
              </div>
            </div>
            <div className='col-lg-3 col-6 mt-3 mt-lg-0'>
              <div className='text-white text-shadow-sm'>
                <ReactVisibilitySensor partialVisibility>
                  {({ isVisible }) => (
                    <div className='d-flex flex-column justify-content-between align-items-center'>
                      <i className='bi-puzzle fs-1'></i>
                      {isVisible ? (
                        <CountUp
                          className='text-sans-serif font-weight-bold display-4'
                          end={8000}
                          duration={5}
                        />
                      ) : null}
                      <p className='badge bg-dark bg-opacity-75'>Servicios</p>
                    </div>
                  )}
                </ReactVisibilitySensor>
              </div>
            </div>
            <div className='col-lg-3 col-6 mt-3 mt-lg-0'>
              <div className='text-white text-shadow-sm'>
                <ReactVisibilitySensor partialVisibility>
                  {({ isVisible }) => (
                    <div className='d-flex flex-column justify-content-between align-items-center'>
                      <i className='bi-bag-check fs-1'></i>
                      {isVisible ? (
                        <CountUp
                          className='text-sans-serif font-weight-bold display-4'
                          end={8000}
                          duration={5}
                        />
                      ) : null}
                      <p className='badge bg-dark bg-opacity-75'>Entregas</p>
                    </div>
                  )}
                </ReactVisibilitySensor>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Parallax>
  );
};

export default Statistics;

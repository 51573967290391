import { useDispatch } from 'react-redux';
import { addBusiness } from '../store/businesses';
// import jwt from "jwt-decode";
import { useState, useEffect } from "react";

const useBusiness = (initialState, callback, validate) => {
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleTimeChange = ({ name, value }) => {
    setValues({
      ...values,
      [name]: value,
    });
  } 

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrors(validate(values));
    setIsSubmitting(true);
    dispatch(addBusiness(values));
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log(values);
      callback();
    }
  }, [errors]);

  return { handleChange, handleTimeChange, handleSubmit, values, errors };
};

export default useBusiness;

import L from 'leaflet';

export const currentLocationIcon = L.icon({
  iconUrl: require('../assets/img/markers/lime-marker.png').default,
  iconRetinaUrl: require('../assets/img/markers/lime-marker@2x.png').default,
  shadowUrl: require('../assets/img/markers/marker-shadow.png').default,
  iconSize: [26, 41],
  iconAnchor: [13, 41],
  popupAnchor: [0, -41],
});

export const businessIcon = L.icon({
  iconUrl: require('../assets/img/markers/blue-marker.png').default,
  iconRetinaUrl: require('../assets/img/markers/blue-marker@2x.png').default,
  shadowUrl: require('../assets/img/markers/marker-shadow.png').default,
  iconSize: [26, 41],
  iconAnchor: [13, 41],
  popupAnchor: [0, -41],
});

export const serviceIcon = L.icon({
  iconUrl: require('../assets/img/markers/pink-marker.png').default,
  iconRetinaUrl: require('../assets/img/markers/pink-marker@2x.png').default,
  shadowUrl: require('../assets/img/markers/marker-shadow.png').default,
  iconSize: [26, 41],
  iconAnchor: [13, 41],
  popupAnchor: [0, -41],
});

export const courierIcon = L.icon({
  iconUrl: require('../assets/img/markers/orange-marker.png').default,
  iconRetinaUrl: require('../assets/img/markers/orange-marker@2x.png').default,
  shadowUrl: require('../assets/img/markers/marker-shadow.png').default,
  iconSize: [26, 41],
  iconAnchor: [13, 41],
  popupAnchor: [0, -41],
});

import { createSlice } from "@reduxjs/toolkit";
import { apiCallInitiated } from "./api";
import { getToken } from '../components/auth/User';
import { equalized as equalizedProduct } from "./products";

const urlNew = "/api/Stores";
const urlAddProduct = "api/Products";

const businessSlice = createSlice({
  name: "business",
  initialState: {
    loading: false,
    current: null,
    list: [],
    byId: {},
    loaded: false,
  },
  reducers: {
    businessApiCallInitiated: (businesses) => {
      businesses.loading = true;
    },
    businessApiCallFailed: (businesses) => {
      businesses.loading = false;
    },
    businessLoaded: (business, action) => {
      const dataBusiness = action.payload.value;

      console.log(dataBusiness);
      business.current = dataBusiness[0];
      business.loaded = true;
      business.loading = false;
    },
    businessCreated: (business, action) => {
      const newBusiness = action.payload;
      business.current = newBusiness;
    },
    productCreatedApiCallInitiated:(businesses) => {
      businesses.loading = true;
    },
    productCreatedApiCallFailed:(businesses) => {
      businesses.loading = false;
    },
    productsLoaded: (business, action) => {
      const productsList = action.payload.value;
      console.log(productsList);
      productsList.forEach((product) => {
        business.byId[product.id] = product;
        !business.list.includes(product.id) &&
        business.list.push(product.id);
      });
      business.loaded = true;
      business.loading = false;
    },
    productAdded: (business, action) => {
      const newProduct = action.payload;
      console.log('productAdded:'+newProduct);
      business.list.byId[newProduct.id] = newProduct;
      business.list.push(newProduct.id);
    },
  },
});

export default businessSlice.reducer;

const {
  businessApiCallInitiated,
  businessApiCallFailed,
  businessLoaded,
  businessCreated,
  productCreatedApiCallInitiated,
  productCreatedApiCallFailed,
  productsLoaded,
  productAdded,
} = businessSlice.actions;

const equalized = (business) => {
  const {
    name,
    phone,
    address,
    ownerId,
    /* latitude,
    longitude,
    openingTime,
    closingTime,
    logo,
    storeRate,
    ownerId,
    municipalityId,
    categoryId,
    isActiveStore,
    is24Hours,
    category,
    municipality,
    owner,
    products, */
  } = business;
  return {
    storeName: name,
    phoneNumber: Number(phone),
    storeAddres: address,
    ownerId: ownerId,
   /* storeLatitude: latitude|null,
    storeLongitude: longitude|null,
    openingTime: openingTime|null,
    closingTime: closingTime|null,
    activateDiscount: false,
    elaborationTime: 15,
    logo: logo|null,
    storeRate: storeRate|null,
    ownerId: ownerId,
    municipalityId: municipalityId|null,
    categoryId: categoryId|null,
    isActiveStore: isActiveStore|false,
    isOpenToday: false,
    canBeBooked: true,
    canBePickedUp: true,
    is24Hours: is24Hours|false,
    imagesFolderURL: null,
    generalDiscount: 0,
    category: category|null,
    municipality: municipality|null,
    owner: owner|null,
    products: products|[],
    storeCloseDay: [],*/
  };
};

export const loadBusiness = async (ownerId) =>
  await apiCallInitiated({
    url: "/api/Stores/store-by-owner/"+ownerId,
    method: "get",
    onStart: businessApiCallInitiated.type,
    onSuccess: businessLoaded.type,
    onError: businessApiCallFailed.type,
    headers: {
      Accept: "*/*",
      // 'Content-Type': 'application/json',
      //"Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      Authorization: 'Bearer ' + getToken()
    },
  });

export const addBusiness = (business) =>
  apiCallInitiated({
    url: urlNew,
    method: "post",
    onStart: businessApiCallInitiated.type,
    onSuccess: businessCreated.type,
    onError: businessApiCallFailed.type,
    headers: {
      Accept: "*/*",
      // 'Content-Type': 'application/json',
      //"Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      Authorization: 'Bearer ' + getToken()
    },
    body: equalized(business),
  });

  export const loadProducts = (businessID) =>
  apiCallInitiated({
    url: "api/Products/by-store/"+businessID,
    method: "post",
    onStart: productCreatedApiCallInitiated.type,
    onSuccess: productsLoaded.type,
    onError: productCreatedApiCallFailed.type,
    headers: {
      Accept: "*/*",
      // 'Content-Type': 'application/json',
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      Authorization: 'Bearer ' + getToken()
    },
  });

  export const addProduct = (product) =>
  apiCallInitiated({
    url: urlAddProduct,
    method: "post",
    onStart: productCreatedApiCallInitiated.type,
    onSuccess: productAdded.type,
    onError: productCreatedApiCallFailed.type,
    headers: {
      Accept: "*/*",
      // 'Content-Type': 'application/json',
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      Authorization: 'Bearer ' + getToken()
    },
    body: equalizedProduct(product),
  });
import useProducts from "../../hooks/useProducts";
import validate from "./validateProducts";
import FileInput from "../common/FileInput";
import { useDispatch } from "react-redux";
import { addProduct } from "../../store/business";

const AddProducts = (props) => {
  const handleOnSubmit = props.onSubmit;
  const initialState = {
    name: "",
    description: "",
    price: "",
    business: null,
    image: "",
  };
  const dispatch = useDispatch();
  const submitForm = () => {
    console.log("Submit Product.");
    dispatch(addProduct(values));
    handleOnSubmit();
  };
  const { handleChange, handleSubmit, values, errors } = useProducts(
    initialState,
    submitForm,
    validate
  );

  const handleOnClickBtnSubmit = () => {
    console.log("Click on BtnSubmit Product From.");
  }

  return (
    <section className="form-container widget">
      <form className="form vertical-form" onSubmit={handleSubmit}>
        {/* <div className="g-3"> */}
          <header className="header">
            <h2 className="heading">Agregar producto</h2>
          </header>
        {/* </div> */}
        
        <div className="py-3">
          <div className="col-12">
            <label className="form-label" htmlFor="name">
              Nombre
            </label>
            <input
              type="text"
              className="form-control form-control-sm"
              name="name"
              id="name"
              placeholder="Introduzca el nombre del producto"
              value={values.name}
              onChange={handleChange}
            />
            {errors.name && <p>{errors.name}</p>}
          </div>
          <div className="col-12">
            <label className="form-label" htmlFor="description">
              Descripción
            </label>
            <textarea
              type="text"
              className="form-control form-control-sm"
              name="description"
              id="description"
            />
          </div>
          <div className="col-12">
            <label className="form-label" htmlFor="image">
              Foto descriptiva
            </label>
            <FileInput
              name="image"
              value={values.image}
              onChange={handleChange}
            />
          </div>
          <div className="col-12">
            <label className="form-label" htmlFor="price">
              Precio
            </label>
            <input
              type="number"
              className="form-control form-control-sm text-end"
              name="price"
              id="price"
              placeholder="Introduzca el valor de precio"
              value={values.price}
              onChange={handleChange}
            />
            {errors.price && <p>{errors.price}</p>}
          </div>
        </div>
        <footer className="footer">
          <button
            className="btn btn-outline-primary btn-sm"
            type="submit"
            onClick={handleOnClickBtnSubmit}
          >
            <i className="bi bi-check2-square me-2"></i>Agregar
          </button>
          <button className="btn btn-outline-danger btn-sm" type="reset">
            <i className="bi bi-x-square me-2"></i>Cancelar
          </button>
        </footer>
      </form>
    </section>
  );
};

export default AddProducts;

import { createSlice } from '@reduxjs/toolkit';

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    navbarColor: 'colored',
    sidebarStatus: 'expanded',
  },
  reducers: {
    changeNavbarColor: (ui, action) => {
      ui.navbarColor = action.payload;
    },
    changeSidebarStatus: (ui, action) => {
      ui.sidebarStatus = action.payload;
    },
  },
});

export default uiSlice.reducer;

export const { changeNavbarColor, changeSidebarStatus } = uiSlice.actions;

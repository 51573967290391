import { createSlice } from '@reduxjs/toolkit';
import { apiCallInitiated } from './api';

const url = '/api/Categories/'/*'/categories'*/;

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    loading: false,
    byId: {},
    list: [],
  },
  reducers: {
    categoriesApiCallInitiated: (categories) => {
      categories.loading = true;
    },
    categoriesApiCallFailed: (categories) => {
      categories.loading = false;
    },
    categoriesLoaded: (categories, action) => {
      const categoriesList = action.payload;

      categoriesList.forEach((category) => {
        categories.byId[category.id] = category;
        !categories.list.includes(category.id) &&
          categories.list.push(category.id);
      });

      categories.loading = false;
    },
    categoryAdded: (categories, action) => {
      const newCategory = action.payload;
      categories.byId[newCategory.id] = newCategory;
      categories.push(newCategory.id);
    },
  },
});

export default categoriesSlice.reducer;

const {
  categoriesApiCallInitiated,
  categoriesApiCallFailed,
  categoriesLoaded,
  categoryAdded,
} = categoriesSlice.actions;

export const loadCategories = () =>
  apiCallInitiated({
    url,
    method: 'get',
    onStart: categoriesApiCallInitiated.type,
    onSuccess: categoriesLoaded.type,
    onError: categoriesApiCallFailed.type,
  });

export const addCategory = () =>
  apiCallInitiated({
    url,
    method: 'post',
    onStart: categoriesApiCallInitiated.type,
    onSuccess: categoryAdded.type,
    onError: categoriesApiCallFailed.type,
    headers: {
      Accept: '*/*',
      Authorization: 'Basic',
      // 'Content-Type': 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });

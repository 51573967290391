import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { loadCategories } from '../../store/categories';
import { loadBusinesses } from '../../store/businesses';
import { changeNavbarColor } from '../../store/ui';
import CategoriesCarousel from './CategoriesCarousel';
import Landing from './Landing';
import Partner from './Partner';
import Presence from './Presence';
import Statistics from './Statistics';

const Home = () => {
  const dispatch = useDispatch();
  const navbarColor = useSelector((state) => state.ui.navbarColor);

  useEffect(() => {
    dispatch(changeNavbarColor('transparent'));

    dispatch(loadCategories());
    dispatch(loadBusinesses());
    // dispatch(loadSubcategories());
    // dispatch(loadOrders());
    // dispatch(loadCategories());
    // dispatch(loadCategories());
    // dispatch(loadCategories());
    // dispatch(loadCategories());
  }, [dispatch]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = document.scrollingElement.scrollTop;
      scrollPosition >= 120
        ? navbarColor !== 'colored' && dispatch(changeNavbarColor('colored'))
        : navbarColor !== 'transparent' &&
          dispatch(changeNavbarColor('transparent'));
    };
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
      navbarColor !== 'colored' && dispatch(changeNavbarColor('colored'));
    };
  }, [dispatch, navbarColor]);

  return (
    <section>
      <Landing />
      <CategoriesCarousel />
      <Presence />
      <Partner />
      <Statistics />
    </section>
  );
};

export default Home;

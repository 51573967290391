import React from 'react';
import { useSelector } from 'react-redux';
// import { gradients } from '../../utils/gradients';
import AddCategory from '../admin/AddCategory';
import CategoriesTable from './CategoriesTable';
import Widget from './widgets/Widget';
import WidgetIcon from './widgets/WidgetIcon';
import WidgetDetails from './widgets/WidgetDetails';
import { gradients } from '../../utils/gradients';

const CategoriesDashboard = () => {
  const categories = useSelector((state) => state.entities.categories);
  const subcategories = useSelector((state) => state.entities.subcategories);

  return (
    <section className='categories-dashboard dashboard__content'>
      <div>
      <CategoriesTable />
      </div>
      <AddCategory />
      <div>
      <Widget>
        <WidgetIcon icon='bookmark' gradient={gradients.GRADIENT_DEEP_BLUE} />
        <WidgetDetails
          description='Categorías'
          counter={categories.list.length}
        />
        <WidgetIcon icon='bookmarks' gradient={gradients.GRADIENT_STAR_WINE} />
        <WidgetDetails
          description='Subcategorías'
          counter={subcategories.list.length}
        />
      </Widget>
      </div>
    </section>
  );
};

export default CategoriesDashboard;

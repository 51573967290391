import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { range } from 'lodash';

const Pagination = ({ itemsCount, pageSize, currentPage, onPageChange }) => {
  const pagesCount = Math.ceil(itemsCount / pageSize);
  const maxPagesToDisplay = 10;
  const [pages, setPages] = useState([]);

  useEffect(() => {
    setPages(
      range(
        1,
        pagesCount <= maxPagesToDisplay ? pagesCount + 1 : maxPagesToDisplay + 1
      )
    );
  }, [pagesCount]);

  if (pagesCount === 1) return null;

  const handleChange = (page) => {
    onPageChange(page);
    pagesCount > maxPagesToDisplay &&
      setPages(
        page <= maxPagesToDisplay / 2
          ? range(
              1,
              pagesCount <= maxPagesToDisplay
                ? pagesCount + 1
                : maxPagesToDisplay + 1
            )
          : page + maxPagesToDisplay / 2 <= pagesCount
          ? range(
              1 + page - maxPagesToDisplay / 2,
              pagesCount <= maxPagesToDisplay
                ? pagesCount + 1
                : maxPagesToDisplay + page - maxPagesToDisplay / 2 + 1
            )
          : range(pagesCount + 1 - 10, pagesCount + 1)
      );
  };

  return (
    <nav>
      <ul className='pagination pagination-sm'>
        {currentPage > 1 ? (
          <li className='page-item'>
            <button
              className='page-link'
              aria-label='Previous'
              onClick={() => handleChange(currentPage - 1)}>
              <span aria-hidden='true'>&laquo;</span>
            </button>
          </li>
        ) : (
          <li className='page-item disabled'>
            <button className='page-link' aria-label='Previous'>
              <span aria-hidden='true'>&laquo;</span>
            </button>
          </li>
        )}

        {pages.map((page) => (
          <li
            key={page}
            className={page === currentPage ? 'page-item active' : 'page-item'}>
            <button className='page-link' onClick={() => handleChange(page)}>
              {page}
            </button>
          </li>
        ))}
        {currentPage < pagesCount ? (
          <li className='page-item'>
            <button
              className='page-link'
              aria-label='Next'
              onClick={() => handleChange(currentPage + 1)}>
              <span aria-hidden='true'>&raquo;</span>
            </button>
          </li>
        ) : (
          <li className='page-item disabled'>
            <button className='page-link' aria-label='Next'>
              <span aria-hidden='true'>&raquo;</span>
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
};

Pagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;

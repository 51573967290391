import React, { useState } from "react";
/*import '../../styles/components/_form.scss';*/
import FormSignup from "./SignupForm";

const RegisterUser = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const submitForm = () => {
    setIsSubmitted(true);
    
    /*
    axios.post('http://alflay.com/api/User/1', {
      "Name": "Marcel",
      "UserLastName": "Fuentes Alonso",
      "Picture": "srcImages\\imgBussines\\Category_Snack.jpg",
      "Active": false,
      "UserName": "marcelFuentes2011",
      "Email": "yfuenteslora@gmail.com",
      "PasswordHash": "Keopsyad2020@",
      "PhoneNumber": "53112155"
  }).then(response => {
      setLoading(false);
      setUserSession(response.data.token, response.data.user);
      props.history.push('/dashboard');
    }).catch(error => {
      console.log(error);
      setLoading(false);
      //if (error.response.statusCode === 401) setError(error.response.data.message);
      //else setError("Something went wrong. Please try again later.");
    });
  */  
 };

  return (
    <>
      <div className="form-container">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col col-lg-8">
              {!isSubmitted ? (
                <FormSignup submitForm={submitForm} />
              ) : (
                <div className="form-content-right">
                  <h1 className="form-success">
                    ¡Hemos recibido su solicitud!
                  </h1>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RegisterUser;

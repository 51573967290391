import React from "react";
import { useLocation } from "react-router-dom";
function NotFound() {
  let location = useLocation();
  return (
    <section className="py-5 error-page">
      <div className="py-5 container">
        <h1>404- Page not Found</h1>
        <h3>
          No match for <code>{location.pathname}</code>
        </h3>
      </div>
    </section>
  );
}
export default NotFound;

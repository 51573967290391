import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadBusiness, loadProducts } from "../../store/business";
import AddBusiness from "../admin/AddBusiness";
import ShowBusiness from "../admin/ShowBusiness";

const BusinessesManagement = ({user}) => {
  const business = useSelector((state) => state.entities.business);
  const dispatch = useDispatch();
  const onLoadBusiness = useCallback(
    async (event) => {
      console.log("onLoadBusiness useCallback event:" + JSON.stringify(event));
      if(!business.loaded && (business.current == null)){
        dispatch( await loadBusiness(user.userId));
      }
    },
    [dispatch]
  );
  useEffect(
    (event) => {
      console.log("useEffect event:" + JSON.stringify(event));
      onLoadBusiness();
    },
    [onLoadBusiness]
  ); 

  const onLoadProductsListBusiness = useCallback(
    async (event) => {
      console.log("onLoadBusiness useCallback event:" + JSON.stringify(event));
      if(business.loaded && (business.current != null)){
        dispatch( await loadProducts(business.current.userId));
      }
    },
    [onLoadBusiness]
  );

  useEffect(
    (event) => {
      console.log("useEffect event:" + JSON.stringify(event));
      onLoadProductsListBusiness();
    },
    [onLoadProductsListBusiness]
  ); 
/*
  useEffect((event) => {
    // componentWillUnmount
    console.log("useEffect componentWillUnmount:" + JSON.stringify(event));
    //loadBusiness(user.userId);
    dispatch(loadBusiness(user.userId));
    return () => {
       // Your code here
       //dispatch(loadBusiness(user.userId));
       console.log('useEffect return.');
    }
  }, [user.userId]); */
  
  if(!business.loaded && (business.current == null)){
    //dispatch(loadBusiness(user.userId));
    return (
      <section className="content">
        <AddBusiness/>
      </section>
    );
  }
  
  return (
    <section className="content">
      { (business.current != null) && <ShowBusiness business={business}/> }
    </section>
  );
};

export default BusinessesManagement;

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const SidebarLink = ({ to, icon, label, ...rest }) => {
  return (
    <li className='nav-item'>
      <NavLink
        {...rest}
        to={`${to}`}
        className='nav-link link-dark d-flex flex-row align-items-center justify-content-start'>
        <i className={`bi bi-${icon} me-2`}></i>
        {label}
      </NavLink>
    </li>
  );
};

SidebarLink.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

SidebarLink.defaultProps = {
  to: '#',
  icon: 'link-45deg',
  label: 'Sidebar Link',
};

export default SidebarLink;

import React from 'react';
import PropTypes from 'prop-types';

const FileInput = ({ name, value, onChange: handleChange }) => {
  return (
    <div className='file-uploader'>
      <input
        value={value}
        onChange={handleChange}
        type='file'
        className='form-control form-control-sm'
        name={name}
        id={name}
      />
      {/* <button className='btn btn-primary'>Elija el archivo</button> */}
    </div>
  );
};

FileInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default FileInput;

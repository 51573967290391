import React from 'react';
import PropTypes from 'prop-types';
import { Marker, Popup } from 'react-leaflet';
import { serviceIcon } from '../../utils/markerIcons';

const ServiceMarker = ({ service }) => {
  const { storeName, storeLongitude, storeLatitude } = service;
  return (
    <Marker
      icon={serviceIcon}
      position={{
        lng: storeLongitude,
        lat: storeLatitude,
      }}>
      <Popup>
        <p>{storeName}</p>
      </Popup>
    </Marker>
  );
};

ServiceMarker.propTypes = {
  service: PropTypes.object.isRequired,
};

export default ServiceMarker;

import React from 'react';
import Navbar from '../common/Navbar';
import PropTypes from 'prop-types';
import Footer from './Footer';
import { useLocation } from 'react-router';
import { ToastContainer } from 'react-toastify';

const Layout = (props) => {
  const location = useLocation();
  return (
    <React.Fragment>
      <ToastContainer />
      <Navbar />
      <main className='flex-shrink-0 flex-grow-1'>{props.children}</main>
      {!location.pathname.includes('dashboard') && <Footer />}
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.object.isRequired,
};

export default Layout;

import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import AccessPage from "./components/auth/AccessPage";
import NotFound from "./components/common/NotFound";
import Dashboard from "./components/dashboard/Dashboard";
import Home from "./components/home/Home";
import Profile from "./components/auth/Profile";
import Management from "./components/management/Management";
import { checkAuthentication } from "./store/auth";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

const Routes = () => {
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.auth.authToken);
  const onCheckAuth = useCallback(
    (event) => {
      console.log("useCallback event:" + JSON.stringify(event));
      dispatch(checkAuthentication());
    },
    [dispatch]
  );
  useEffect(
    (event) => {
      console.log("useEffect event:" + JSON.stringify(event));
      onCheckAuth();
    },
    [onCheckAuth]
  );

  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/access">
        <CheckedAuthRoute userIsLogged={(authToken !== null)} isRequired={false} />
        <AccessPage />
      </Route>
      <Route path="/profile">
        <CheckedAuthRoute userIsLogged={(authToken !== null)} />
        <Profile />
      </Route>
      <Route path="/management">
        <CheckedAuthRoute userIsLogged={(authToken !== null)} />
        <Management />
      </Route>
      <Route path="/dashboard">
        <CheckedAuthRoute userIsLogged={(authToken !== null)} />
        <Dashboard />
      </Route>
      {/* <Route path="/management">
        <Management />
      </Route>
      <Route path="/dashboard">
        <Dashboard />
      </Route> */}

      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};

const CheckedAuthRoute = ({ userIsLogged, isRequired }) => {
  const path = isRequired ? "/access?target=login" : "/";
 /*  if(!isRequired && userIsLogged){
    return <Redirect to={path} />
  }else if(isRequired && !userIsLogged){
    return <Redirect to={path} />
  }  */
  return ((!isRequired && userIsLogged) || (isRequired && !userIsLogged)) && <Redirect to={path} />;
};
CheckedAuthRoute.propTypes = {
  userIsLogged: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool
};
CheckedAuthRoute.defaultProps = {
  userIsLogged: false,
  isRequired: true,
};

export default Routes;

import { createSlice } from '@reduxjs/toolkit';
import { apiCallInitiated } from './api';

const url = '/orders';

const ordersSlice = createSlice({
  name: 'orders',
  initialState: {
    loading: false,
    byId: {},
    list: [],
  },
  reducers: {
    ordersApiCallInitiated: (orders) => {
      orders.loading = true;
    },
    ordersApiCallFailed: (orders) => {
      orders.loading = false;
    },
    ordersLoaded: (orders, action) => {
      const ordersList = action.payload;

      ordersList.forEach((order) => {
        orders.byId[order.id] = order;
        !orders.list.includes(order.id) && orders.list.push(order.id);
      });

      orders.loading = false;
    },
    orderAdded: (orders, action) => {
      const newOrder = action.payload;
      orders.byId[newOrder.id] = newOrder;
      orders.push(newOrder.id);
    },
  },
});

export default ordersSlice.reducer;

const {
  ordersApiCallInitiated,
  ordersApiCallFailed,
  ordersLoaded,
  orderAdded,
} = ordersSlice.actions;

export const loadOrders = () =>
  apiCallInitiated({
    url,
    method: 'get',
    onStart: ordersApiCallInitiated.type,
    onSuccess: ordersLoaded.type,
    onError: ordersApiCallFailed.type,
  });

export const addOrder = () =>
  apiCallInitiated({
    url,
    method: 'post',
    onStart: ordersApiCallInitiated.type,
    onSuccess: orderAdded.type,
    onError: ordersApiCallFailed.type,
    headers: {
      Accept: '*/*',
      Authorization: 'Basic',
      // 'Content-Type': 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });

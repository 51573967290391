import React from 'react';
import WidgetIcon from './WidgetIcon';
import WidgetDetails from './WidgetDetails';
import { useSelector } from 'react-redux';

const CategoriesWidget = (props) => {
  const categories = useSelector((state) => state.entities.categories);

  return (
    <article className='p-3 widget widget--light categories-widget'>
      <WidgetIcon gradient='gradient--grown-early' icon='box-seam' />
      <h2 className='widget__counter ms-4 text-nowrap'>Información General</h2>
      <WidgetDetails
        description='Categorías'
        counter={categories.list.length}
      />
      {categories.list.map((category) => (
        <WidgetDetails
          key={category}
          description={categories.byId[category].name}
          counter={5}
        />
      ))}
    </article>
  );
};

CategoriesWidget.propTypes = {};

export default CategoriesWidget;

import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <section className='footer mt-auto bg-light pt-5 pb-2'>
      <div className='container'>
        <div className='row w-100'>
          <div className='col-md-6 col-lg-2 mx-2 mx-md-0'>
            <h5>AlFlay</h5>
            <ul className='nav flex-column'>
              <li className='nav-item mb-2'>
                <Link to='/' className='nav-link p-0 text-muted'>
                  ¿Quiénes somos?
                </Link>
              </li>
              <li className='nav-item mb-2'>
                <Link to='#' className='nav-link p-0 text-muted'>
                  Trabaja con nosotros
                </Link>
              </li>
              <li className='nav-item mb-2'>
                <Link to='#' className='nav-link p-0 text-muted'>
                  Administra tu cuenta
                </Link>
              </li>
              <li className='nav-item mb-2'>
                <Link to='#' className='nav-link p-0 text-muted'>
                  Preguntas frecuentes
                </Link>
              </li>
            </ul>
          </div>

          <div className='col-md-6 col-lg-2 m-2 m-md-0'>
            <h5>Legal</h5>
            <ul className='nav flex-column'>
              <li className='nav-item mb-2'>
                <Link to='#' className='nav-link p-0 text-muted'>
                  Términos y condiciones
                </Link>
              </li>
              <li className='nav-item mb-2'>
                <Link to='#' className='nav-link p-0 text-muted'>
                  Privacidad
                </Link>
              </li>
              <li className='nav-item mb-2'>
                <Link to='#' className='nav-link p-0 text-muted'>
                  Cookies
                </Link>
              </li>
            </ul>
          </div>

          <div className='col-md-6 col-lg-2 mx-2 mx-md-0 my-2 mt-lg-0'>
            <h5>Soporte</h5>
            <ul className='nav flex-column'>
              <li className='nav-item mb-2'>
                <a
                  href='mailto:soporte@alflay.com'
                  className='nav-link p-0 text-muted'>
                  soporte@alflay.com
                </a>
              </li>
            </ul>
            <h5>Matanzas</h5>
            <ul className='nav flex-column'>
              <li className='nav-item mb-2'>
                <a href='tel:+5354557244' className='nav-link p-0 text-muted'>
                  +53 5 455 7244
                </a>
              </li>
            </ul>
          </div>

          <div className='col-md-6 col-lg-4 offset-lg-1 mt-4 mt-md-2 d-flex justify-content-center align-items-center gap-2'>
            <img
              src={
                require('../../assets/img/Download_on_the_App_Store_Badge_ES_RGB_blk_100217.svg')
                  .default
              }
              alt=''
              height='45px'
            />
            <img
              src={
                require('../../assets/img/google-play-badge@45px.jpg').default
              }
              alt=''
              height='45px'
            />
          </div>
        </div>

        <div className='d-flex justify-content-between pt-4 mt-4 border-top'>
          <p>&copy; {new Date().getFullYear()} AlFlay</p>
          <ul className='list-unstyled d-flex'>
            <li className='ms-3'>
              <Link className='link-dark' to='#'>
                <i className='bi-twitter'></i>
              </Link>
            </li>
            <li className='ms-3'>
              <Link className='link-dark' to='#'>
                <i className='bi-instagram'></i>
              </Link>
            </li>
            <li className='ms-3'>
              <Link className='link-dark' to='#'>
                <i className='bi-facebook'></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Footer;

import React from 'react';
import PropTypes from 'prop-types';

const SidebarCollapsible = ({ children, label, icon }) => {
  const target = label.toLowerCase().replace(/\s/g, '-');

  return (
    <li className='nav-item'>
      <button
        className='btn btn-toggle collapsed nav-link link-dark d-flex flex-row align-items-center justify-content-start w-100'
        data-bs-toggle='collapse'
        data-bs-target={`#${target}-collapse`}
        aria-expanded='false'>
        <i className={`bi bi-${icon} me-2`}></i>
        {label}
      </button>
      <div className='collapse' id={`${target}-collapse`}>
        <ul className='btn-toggle-nav list-unstyled pb-1 small ms-2'>
          {children}
        </ul>
      </div>
    </li>
  );
};

SidebarCollapsible.propTypes = {
  children: PropTypes.array.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

SidebarCollapsible.defaultProps = {
  icon: 'link',
  label: 'Sidebar Collapsible',
};

export default SidebarCollapsible;

import React from "react";
import validate from "./validateInfo";
import useForm from "../../hooks/useForm";

const SignupForm = ({ submitForm }) => {
  const { handleChange, handleSubmit, values, errors, isSubmitting} = useForm(
    submitForm,
    validate
  );
  
  return (
    <>
      <form onSubmit={handleSubmit} className="g-3 form was-validated" noValidate>
        <h1>
          ¡Comience con nosotros hoy mismo! <small className="info">Óbtenga su cuenta llenando la información a continuación.</small> 
        </h1>
        <div className="form-inputs mb-3">
          <label className="form-label" htmlFor="username">
            Usuario
          </label>
          <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="bi bi-person-fill"></i>
                  </span>
                </div>
          <input
            id="username"
            className="form-control"
            type="text"
            name="username"
            placeholder="Introduzca su usuario"
            value={values.username}
            onChange={handleChange}
            required
            minLength={3}
          />
          </div>
          {errors.username ? <p className="form-text text-danger">{errors.username}</p> : ''}
        </div>
        <div className="form-inputs mb-3">
          <label htmlFor="telephone" className="form-label">
            Teléfono
          </label>
          <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="bi bi-phone-fill"></i>
                  </span>
                </div>
          <input
            type="text"
            pattern="[0-9]*"
            maxLength="14"
            minLength="6"
            value={values.telephone}
            name="telephone"
            onChange={handleChange}
            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
            className="form-control"
            id="telephone"
            placeholder="Introduzca su número de teléfono"
            required
          /></div>
          {errors.telephone && (
            <span className="error text-danger form-text">{errors.telephone}</span>
          )}
        </div>
        <div className="form-inputs mb-3">
          <label className="form-label" htmlFor="email">
            Correo Electrónico
          </label>
          <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="bi bi-envelope-fill"></i>
                  </span>
                </div>
          <input
            id="email"
            className="form-control"
            type="email"
            name="email"
            placeholder="Introduzca su correo electrónico"
            required
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
            //pattern="^(([-\w\d]+)(\.[-\w\d]+)*@([-\w\d]+)(\.[-\w\d]+)*(\.([a-zA-Z]{2,5}|[\d]{1,3})){1,2})$"
            value={values.email}
            onChange={handleChange}
          />
          </div>
          {errors.email && <p className="error text-danger form-text">{errors.email}</p>}
        </div>
        <div className="form-inputs mb-3">
          <label className="form-label" htmlFor="password">
            Contraseña
          </label>
          <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="bi bi-lock-fill"></i>
                  </span>
                </div>
          <input
            id="password"
            className="form-control"
            type="password"
            name="password"
            placeholder="Introduzca su contraseña"
            value={values.password}
            onChange={handleChange}
            required
          />
          </div>
          {errors.password && <p className="error text-danger form-text">{errors.password}</p>}
        </div>
        <div className="form-inputs mb-3">
          <label className="form-label" htmlFor="repeat-password">
            Confirmar Contraseña
          </label>
          <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="bi bi-lock-fill"></i>
                  </span>
                </div>
          <input
            id="repeat-password"
            className="form-control"
            type="password"
            name="password2"
            placeholder="Confirme su contraseña"
            value={values.password2}
            onChange={handleChange}
            required
          />
          </div>
          {errors.password2 && <p className="error text-danger form-text">{errors.password2}</p>}
        </div>
        <footer className='my-3 footer'>
          <div className="row">
          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
        <button className="btn btn-outline-primary" type="submit"disabled={isSubmitting} >
          {/* Regístrese */}
          {isSubmitting ? (<><span className="spinner-border spinner-border-sm"></span> Loading...</>) : "Regístrese"}
        </button>
        </div>
          </div>
        
        </footer>
      </form>
    </>
  );
};

export default SignupForm;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AddProducts from "./AddProducts";
import ProductsTable from "./ProductsTable";

const productTypeOptions = [
  {
    optionId: 'INCOME',
    displayText: 'Income',
  },
  {
    optionId: 'EXPENSES',
    displayText: 'Expenses',
  },
]

const ShowBusiness = ({userSession, business}) => {
  const [state, setState] = useState({
    productsList: [],
    titleInput: '',
    amountInput: '',
    optionId: productTypeOptions[0].optionId,
  });
  const [product, setProduct] = useState({});
  
  const onAddProduct = async (event) => {
    console.log("Add Product.");
    /*
    const {titleInput, amountInput, optionId} = product
    const typeOption = productTypeOptions.find(
      eachTransaction => eachTransaction.optionId === optionId,
    )
    const {displayText} = typeOption*/
    const newProduct = {
      id: state.productsList.length+1,
      title: "Product 1",
      amount: parseInt(10.50),
      type: "Categoria 1",
    }

    await setState(prevState => ({
      productsList: [...prevState.productsList, newProduct],
      titleInput: '',
      amountInput: '',
      optionId: productTypeOptions[0].optionId,
    }))
  }

  useEffect(() => {
    // componentWillUnmount
    console.log('useEffect componentWillUnmount N prod.');
    return () => {
       // Your code here
       console.log('useEffect return N prod.');
    }
  }, [product]);

  return (
    <section className="show-business">
        <h1>Negocio "<i>{business.current.storeName}</i>"</h1>
        <div className="alert alert-warning" role="alert">
          <h4 className="alert-heading">Advertencia!</h4>
          <p>Debe, continuar con la configuración para el correcto funcionamiento de su negocio.</p>
          <hr/>
          <p className="mb-0">Continue con las indicación del enlace <Link className="alert-link" to={""}>de configuración</Link>.</p>
        </div>
        <div className="my-5 row">
          <div className="col-md-6">
            <div className="card border-0 widget">
            <div className="card-body">
                <div className="row d-block d-xl-flex align-items-center">
                    <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                        <div className="icon-shape icon-shape-tertiary rounded me-4 me-sm-0">
                            
                        </div>
                    </div>
                    <div className="col-12 col-xl-7 px-xl-0">
                        <div className="d-none d-sm-block">
                            <h2 className="h6 text-gray-400 mb-0"> Productos</h2>
                            <h3 className="fw-extrabold mb-2">{state.productsList.length}</h3>
                        </div>
                    </div>
                </div>
            </div>
            </div>
          </div>
          <div className="col-md-6"><div className="widget"><h3>{state.productsList.length} <small>Productos</small></h3></div></div>
        </div>
        <div className="my-5 row">
          <div className="col-md-12">
            <AddProducts onSubmit={onAddProduct} product = { setProduct } />
          </div>
        </div>
        <div className="my-5 row">
        <div className="col-md-12">
          <ProductsTable products={state.productsList}/>
          </div>
        </div>
    </section>
  );
}

export default ShowBusiness;
import React from 'react';
import PropTypes from 'prop-types';
import TableHeader from './TableHeader';
import TableBody from './TableBody';

const Table = ({ columns, sortColumn, onSort: handleSort, data }) => {
  return (
    <table className='table table-sm table-hover table-responsive align-middle card-table'>
      <TableHeader
        columns={columns}
        sortColumn={sortColumn}
        onSort={handleSort}
      />
      <TableBody data={data} columns={columns} />
    </table>
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  sortColumn: PropTypes.object.isRequired,
  onSort: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
};

export default Table;

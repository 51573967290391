import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../hooks/useQuery";
import NavItem from "../common/NavItem";
import TabPane from "../common/TabPane";
import LoginForm from "./LoginForm";
import RegisterUser from "./RegisterUser";

const AccessPage = () => {
  const [activeForm, setActiveForm] = useState(useQuery().get("target"));
  const history = useHistory();

  const target =  useQuery().get("target");
  useEffect(() => {
      setActiveForm(target);
  }, [target]);

  const items = [
    {
      name: "login",
      label: "Inicia sesión",
      icon: "person",
      tabPane: <LoginForm />,
    },
    {
      name: "register",
      label: "Crea tu cuenta",
      icon: "person-plus",
      tabPane: <RegisterUser />,
    },
  ];

  const handleClick = (name) => {
    history.push(`?target=${name}`); 
    //setActiveForm(name);
  }

  return (
    <div className="py-5 container">
      <section className="py-5 access-page">
        <nav className="nav nav-pills nav-justified">
          {items.map((item) => (
            <NavItem
              isActive={activeForm === item.name}
              onClick={handleClick}
              name={item.name}
              key={item.name.toString()}
            >
              <i className={`bi bi-${item.icon} me-2`}></i>
              {item.label}
            </NavItem>
          ))}
        </nav>
        <div className="tab__content mt-2">
          {items.map((item) =>
            activeForm === item.name ? (
              <TabPane isActive={true} key={item.name.toString()}>{item.tabPane}</TabPane>
            ) : null
          )}
        </div>
      </section>
    </div>
  );
};

export default AccessPage;
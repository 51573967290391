import React, { useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import { useSelector } from 'react-redux';
import Table from '../common/Table';
import Pagination from '../common/Pagination';
import { paginate } from '../../utils/paginate';

const CategoriesTable = () => {
  const categories = useSelector((state) => state.entities.categories);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(25);
  const [sortColumn, setSortColumn] = useState({ path: 'id', order: 'asc' });
  const [list, setList] = useState(
    categories.list.map((category) => categories.byId[category])
  );

  useEffect(() => {
    setList(
      paginate(
        orderBy(
          categories.list.map((category) => categories.byId[category]),
          sortColumn.path,
          sortColumn.order
        ),
        currentPage,
        pageSize
      )
    );
  }, [categories, sortColumn, currentPage, pageSize]);

  const columns = [
    { path: 'id', label: 'Id' },
    { path: 'name', label: 'Categoría' },
    { path: 'description', label: 'Descripción' },
    { key: 'image' },
    {
      key: 'edit',
      content: (category) => (
        <div className='d-flex flex-row justify-content-end align-items-center'>
          <button
            className='btn btn-outline-warning btn-sm me-1'
            onClick={() => console.log(`${category.name} clicked`)}>
            <i className='bi bi-pencil'></i>
          </button>
          <button
            className='btn btn-outline-danger btn-sm'
            onClick={() => console.log(`${category.name} clicked`)}>
            <i className='bi bi-x'></i>
          </button>
        </div>
      ),
    },
  ];

  const handleSort = (sortCriteria) => {
    setSortColumn(sortCriteria);
    setList(orderBy(list, sortColumn.path, sortColumn.order));
  };

  return (
    <article className='categories__table widget'>
      <header className='widget__header'>
        <h4 className='card-header-title'>Categorías</h4>
        <Pagination
          itemsCount={categories.list.length}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
        <button className='btn btn-sm btn-white'>Exportar</button>
      </header>
      <div className='table-container'>
        <Table
          columns={columns}
          sortColumn={sortColumn}
          onSort={handleSort}
          data={list}
        />
      </div>
    </article>
  );
};

export default CategoriesTable;

import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

const TableBody = ({ data, columns }) => {
  const renderCell = (item, column) => {
    return column.content ? column.content(item) : get(item, column.path);
  };

  const createKey = (item, column) => {
    return item.id + (column.path || column.key);
  };

  return (
    <tbody>
      {(data.length)? data.map((item) => (
        <tr key={item.id}>
          {columns.map((column) => (
            <td key={createKey(item, column)}>{renderCell(item, column)}</td>
          ))}
        </tr>
      )) : <tr>
        <td colSpan={columns.length} className="text-center">No existen elementos</td>
    </tr>}
    </tbody>
  );
};

TableBody.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
};

export default TableBody;

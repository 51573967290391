import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addCategory } from '../../store/categories';
import FileInput from '../common/FileInput';

const AddCategory = () => {
  const initialState = {
    name: '',
    description: '',
    image: '',
  };

  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState(null);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    console.log(e.target.value);
    setErrors({ ...errors, [e.target.name]: null });
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addCategory(values));
  };

  return (
    <section className='form-container vertical-form add-category widget'>
      
      <form className='form row g-3' onSubmit={handleSubmit}>
        <header className='header'>
        <h2 className='heading'>Agregar categoría</h2>
      </header>
        <div className='col-12'>
          <label className='form-label' htmlFor='name'>
            Nombre
          </label>
          <input
            type='text'
            className='form-control form-control-sm'
            name='name'
            id='name'
          />
        </div>
        <div className='col-12'>
          <label className='form-label' htmlFor='description'>
            Descripción
          </label>
          <textarea
            type='text'
            className='form-control form-control-sm'
            name='description'
            id='description'
          />
        </div>
        <div className='col-12'>
          <label className='form-label' htmlFor='image'>
            Foto descriptiva
          </label>
          <FileInput
            name='image'
            value={values.image}
            onChange={handleChange}
          />
        </div>
        <footer className='footer'>
        <button
          className='btn btn-outline-primary btn-sm'
          onClick={handleSubmit}>
          <i className='bi bi-check2-square me-2'></i>Agregar
        </button>
        <button className='btn btn-outline-danger btn-sm' type='reset'>
          <i className='bi bi-x-square me-2'></i>Cancelar
        </button>
      </footer>
      </form>
    </section>
  );
};

export default AddCategory;
